// #region import Zone
import 'antd/dist/reset.css';
import Paper from '@mui/material/Paper';
import { SearchOutlined } from '@ant-design/icons';
import { UserOutlined,RedoOutlined } from '@ant-design/icons';
import { Space , Button, Select, Table , Input , Divider, DatePicker ,  InputNumber,Typography,Form,Popover,Row,Col } from "antd";
import { useState,useEffect } from "react";
import Axios from "axios";

const { TextArea } = Input;

// #endregion


export function 時數系統LOCK(){ 
//#region MEMBER模組
const [member_list_point , set_member_point_list] = useState([]);
const [member_list_staff , set_member_staff_list] = useState([]);
const read_member = async() => {
  let response = await Axios.post("https://pos-back-end.pktriot.net/sU6KZvsY")

  const newarray = response.data.map((item) => {return {value:item.名稱,label:item.名稱 ,}})

  const staff = newarray.filter((item) => item.label.startsWith('ST'));
  set_member_staff_list(staff);

  const point = newarray.filter((item) => item.label.startsWith('PO'));
  set_member_point_list(point)
}
// #endregion

//#region PUNCHCARD 模組(RC)
const [punch_list , set_punch_list] = useState([]);
const read_punch = async(table_object) => {
  let response = await Axios.post("https://pos-back-end.pktriot.net/NBACM_0101L",{
    起始日: table_object.起始日,
    結束日: table_object.結束日,
    門市: table_object.門市,
    夥伴: table_object.夥伴,
  })

  Object.keys(response.data)
  .forEach((key) => {const timestamp = response.data[key].打卡時間;response.data[key].打卡時間 = new Date(timestamp)
  .toLocaleString('zh-TW', {hour12: false})
  .replace(/\//g, "-")})

  const sortedData = response.data.sort((a, b) => new Date(b.打卡時間) - new Date(a.打卡時間));
  set_punch_list(sortedData)

}
const update_punch = async(table_object) => {
  await Axios.post("https://pos-back-end.pktriot.net/NBACM_0103",{
    ID:table_object.ID,
    打卡時間:table_object.打卡時間,
    打卡類型:table_object.打卡類型,
    門市: table_object.門市, 
    夥伴: table_object.夥伴,
    正常時數:table_object.正常時數,
    特殊時數:table_object.特殊時數,
    備註: table_object.備註,
    主管批准作業: table_object.主管批准作業,
  })
  const new_item = {
    ID:table_object.ID,
    打卡時間:table_object.打卡時間,
    打卡類型:table_object.打卡類型,
    門市:table_object.門市,
    夥伴:table_object.夥伴,
    正常時數:table_object.正常時數,
    特殊時數:table_object.特殊時數,
    主管批准作業:table_object.主管批准作業,
    備註:table_object.備註,
  }

  set_punch_list(punch_list.map(item => 
    item.ID === table_object.ID
      ? new_item
      : item
  ));


}
const delete_punch = async(table_object) => {
  await Axios.post("https://pos-back-end.pktriot.net/NBACM_0104",{ ID:table_object.ID, })

  set_punch_list(punch_list.filter(item => item.ID !== table_object.ID ));


}


//#endregion

//#region Table 模組
  const columns_打卡系統 = [
    {
        Key:'0',
        title:'門市',
        dataIndex:'門市',
      },
      {
        Key:'1',
        title:'時間',
        dataIndex:'打卡時間', 
      },
      {
        Key:'2',
        title:'類型',
        dataIndex:'打卡類型', 
      },
      {
        Key:'3',
        title:'夥伴',
        dataIndex:'夥伴',
      },
      {
        Key:'4',
        title:'時數',
        dataIndex:'正常時數',
      },
      {
        Key:'5',
        title:'考勤',
        dataIndex:'考勤',
      },
      {
        Key:'6',
        title:'備註',
        dataIndex:'備註',
      },
      {
        Key:'8',
        title:'操作',
    
        render: (_,table_object) => {
          return( 
          <span>
            <Popover title={table_object.打卡時間} content={
              <Form form={form_打卡清單} name="編輯打卡" onFinish={(data)=>{update_punch(data)}} > 
              <Space direction="vertical"  align="start">
                <Space direction="horizontal"  align="start">

                  <Form.Item name="ID"  /> 
                  <Form.Item name="打卡時間"  /> 

                  <Form.Item name="打卡類型" rules={[{ required: true, message: '必填欄位' }]} >
                    <Select style={{width: 100}} suffixIcon={<UserOutlined/>}  options={list_打卡類型} />  
                  </Form.Item>
                  <Form.Item name="門市" rules={[{ required: true, message: '必填欄位' }]} >
                    <Select style={{width: 130}} suffixIcon={<UserOutlined/>}  options={member_list_point} />  
                  </Form.Item>
                  <Form.Item name="夥伴" rules={[{ required: true, message: '必填欄位' }]} >
                    <Select style={{width: 130}} suffixIcon={<UserOutlined/>}  options={member_list_staff} /> 
                  </Form.Item>

                  <Form.Item name="正常時數" rules={[{ required: false, message: '必填欄位' }]} >
                    <Input style={{ width: 100 }}   type="number" />
                  </Form.Item>
                  <Form.Item name="特殊時數" rules={[{ required: false, message: '必填欄位' }]} >
                    <Input style={{ width: 100 }}   type="number" />
                  </Form.Item>
                </Space>

                <Space direction="horizontal"  align="start">
                  <div > 備註：</div> 
                  <Form.Item name="備註" rules={[{ required: false, message: '必填欄位' }]} >
                    <TextArea rows={3}  style={{ width: 250}} />
                  </Form.Item> 
                  <div > 批准：</div> 
                  <Form.Item name="主管批准作業" rules={[{ required: false, message: '必填欄位' }]} >
                    <TextArea rows={3}  style={{ width: 250}} />
                  </Form.Item> 
                
                 



                <Form.Item><Button type="primary" htmlType="submit">確認</Button></Form.Item>
                </Space>
              </Space>
              </Form>

            } trigger="click"  >
              <Typography.Link onClick={() =>{

                form_打卡清單.setFieldsValue({
                  ID:table_object.ID,
                  打卡時間:table_object.打卡時間,
                  打卡類型:table_object.打卡類型,
                  門市:table_object.門市,
                  夥伴:table_object.夥伴,
                  正常時數:table_object.正常時數,
                  特殊時數:table_object.特殊時數,
                  主管批准作業:table_object.主管批准作業,
                  備註:table_object.備註,

                })

              } } style={{marginRight: 8}}> ✅更新 </Typography.Link>
            </Popover>
              <Typography.Link onClick={() =>{delete_punch(table_object)} } style={{marginRight: 8}}> ❌刪除 </Typography.Link>
          </span>
          )
        }
      } 
      
  ]
  const list_打卡類型 =[{value:"🟢上班",label:"🟢上班"},{value:"🔴下班",label:"🔴下班"}]
//#endregion
//#region 讀取預設資料
const loadData = async () => {
  await read_member()
}
useEffect(() => {loadData()}, [])



// #endregion

//#region 參數項表格
const [form_參數項] = Form.useForm()
const [form_打卡清單] = Form.useForm()
//#endregion

//#region 計算即時
const [總時數, set_總時數] = useState(0)

const calculation = async () => {
  const sum_總時數 = punch_list.reduce((accumulator, currentValue) => {return accumulator + parseFloat(currentValue.正常時數) + parseFloat(currentValue.特殊時數)}, 0);
  set_總時數(sum_總時數) 
}

useEffect(() => {calculation()}, [punch_list])
// #endregion

//#region 結帳狀態模組

return (<div className="App">
<Space direction="horizontal"  align="start">
  <Form name="參數項"  form={form_參數項} onFinish={(data)=>{read_punch(data)}}>
    <Space direction="horizontal" >

      <Paper elevation={3}   >

        <Space direction="vertical" style={{ margin: 15 }}>
          <p style={{ color: '#EB6472' , fontSize: '20px' ,fontWeight: 'bold',textAlign: 'center'}}>參數項<Divider /></p>
          
          <Row>
          <Col flex={1}>
            <Form.Item name="起始日"  rules={[{ required: true, message: '必填欄位' }]}  >
              <DatePicker placeholder={"起始日"} style={{width: 175}} format="YYYY-MM-DD"  />
            </Form.Item>
            <Form.Item name="結束日"  rules={[{ required: true, message: '必填欄位' }]}  >
              <DatePicker placeholder={"結束日"} style={{width: 175}} format="YYYY-MM-DD"  />
            </Form.Item>

            <Form.Item name="門市" rules={[{ required: false, message: '必填欄位' }]}  >
              <Select placeholder={"門市"} showSearch style={{width: 175}}  optionFilterProp="children" filterOption={(input, option) =>(option?.label ?? "").includes(input)}
              filterSort={(optionA, optionB) =>(optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())} options={member_list_point} />
            </Form.Item>

            <Form.Item name="夥伴" rules={[{ required: false, message: '必填欄位' }]}  >
              <Select placeholder={"夥伴"} showSearch style={{width: 175}}  optionFilterProp="children" filterOption={(input, option) =>(option?.label ?? "").includes(input)}
              filterSort={(optionA, optionB) =>(optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())} options={member_list_staff} />
            </Form.Item>

            <Space direction="horizontal" >
            <Form.Item name="清除" >
              <Button  icon={<RedoOutlined />} onClick={() => form_參數項.resetFields(['門市', '夥伴'])} >清除</Button>
            </Form.Item>

            <Form.Item name="查詢" >
              <Button   htmlType="submit" icon={<SearchOutlined /> }>查詢</Button>
            </Form.Item>

            </Space>

          </Col>
          </Row>

        </Space>




      </Paper>

    </Space>  
  </Form>

  <Paper elevation={3}  >

    <Space direction="vertical" style={{ margin: 15 }}>
      <p style={{ color: '#EB6472' , fontSize: '20px' ,fontWeight: 'bold',textAlign: 'center'}}>資料項<Divider /></p>
      <Space direction="vertical" >
        <span>總時數：<InputNumber disabled value={總時數} style={{ width: 85 }} /></span>

      </Space>

    </Space>




  </Paper>

</Space>

<Divider orientation="left">時數表</Divider>
  <Paper elevation={3}  style={{ width: 1000 }} >
    <Table size="small" pagination={{ pageSize: 100 }} columns={columns_打卡系統}  dataSource={punch_list} rowKey={(record) => record.ID}/>
  </Paper>


</div>)




}