import { useState,useRef  } from "react";
import img_Banner from './img/BANNER.png';
import img_Logo_event from './img/LOGO_EVENT.png';

import { Space , Button, Select, Table , Input , Card , Tabs  , InputNumber,Radio,Form,message, MediaQuery  } from "antd";

import { UserOutlined} from '@ant-design/icons';
import Axios from "axios";
import moment from 'moment';

import { useLocation } from "react-router-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { NavBar } from "./Nav"; 
import { Link } from "react-router-dom";

// 頁面掛載

import { 考勤系統 } from "./pages/人資/考勤系統";
import { 品項系統 } from "./pages/人資/品項系統";

import { 金流系統_經理人 } from "./pages/經理人/金流系統";

import { 時數系統_夥伴 } from "./pages/夥伴/時數系統";
import { 額度系統 } from "./pages/夥伴/額度系統";

import { 本日帳務 } from "./pages/門市/本日帳務";
import { 查詢報表 } from "./pages/門市/查詢報表";
import { 查詢會員 } from "./pages/門市/查詢會員";


import { 報表系統 } from "./pages/股東/報表系統";

import { 金流系統 } from "./pages/總部/金流系統";
import { 時數系統 } from "./pages/經理人/時數系統";
import { 給付系統 } from "./pages/總部/給付系統";
//import { 營業概況 } from "./pages/總部/營業概況";




function Main() {

const [form] = Form.useForm()


//#region Table 模組
const columns_打卡系統 = [
    {
      Key:'1',
      title:'時間',
      dataIndex:'打卡時間', 
      fixed: true,
    },
    {
      Key:'2',
      title:'類型',
      dataIndex:'打卡類型', 
      resizable: true,
      ellipsis: true,
    },

    {
      Key:'3',
      title:'夥伴',
      dataIndex:'夥伴',
      resizable: true,
      ellipsis: true,
    },
    {
      Key:'4',
      title:'時數',
      dataIndex:'正常時數',
      resizable: true,
      ellipsis: true,
    },
    {
      Key:'6',
      title:'備註',
      dataIndex:'備註',
      resizable: true,
      ellipsis: true,
    },
    
]

//#endregion
//#region PUNCHCARD 模組(RC)
const [punch_list , set_punch_list] = useState([]);
const read_punch = async(打卡門市) => {
  let response = await Axios.put("https://pos-back-end.pktriot.net/punch_read",{
    門市: 打卡門市,
})
const sortedData = response.data.sort((a, b) => new Date(b.打卡時間) - new Date(a.打卡時間));


Object.keys(sortedData)
.forEach((key) => {
  const timestamp = sortedData[key].打卡時間;
  const date = new Date(timestamp);

  const formattedDate = date.toLocaleString('zh-TW', {year: 'numeric', month: '2-digit', day: '2-digit'});
  const formattedTime = date.toLocaleString('zh-TW', {hour12: false}).split(' ')[1];
  
  sortedData[key].打卡時間 = `${formattedDate.replace(/\//g, "-")} ${formattedTime}`;

})

  
  set_punch_list(sortedData)

}
const create_punch = async(table_object) => {
  await Axios.post("https://pos-back-end.pktriot.net/punch_create", {
    打卡類型: table_object.打卡類型,
    門市: 使用者,  
    夥伴: table_object.夥伴,
    正常時數:table_object.正常時數,
    特殊時數:table_object.特殊時數,
    
    備註: table_object.備註,
  })
  await read_punch(使用者)

}


//#endregion

//#region MEMBER 模組(R)
const [member_staff_list , set_member_staff_list] = useState([]);
const member_staff_read = async(門市) => {
  let response = await Axios.put("https://pos-back-end.pktriot.net/member_staff_read",{門市: 門市,})
  const newarray = response.data.map((item) => {return {value:item.名稱,label:item.名稱 ,}})
  const staff = newarray.filter((item) => item.label.startsWith('ST'));

  staff.sort((a, b) => { if (a.label.match(/^\d+/)) {  return parseInt(a.label) - parseInt(b.label); } else { return a.label.localeCompare(b.label); }  });

  set_member_staff_list(staff)

}
//#endregion



//#region 按鈕讀取模組
const [loadings, setLoadings] = useState([]);
  const enterLoading = (index) => {
    setLoadings((prevLoadings) => {
      const newLoadings = [...prevLoadings];
      newLoadings[index] = true;
      return newLoadings;
    });
    setTimeout(() => {
      setLoadings((prevLoadings) => {
        const newLoadings = [...prevLoadings];
        newLoadings[index] = false;
        return newLoadings;
      });
    }, 500);
  };
//#endregion
//#region 初始使用者
const [密碼, set_密碼] = useState("00000000")
const [使用者, set_使用者] = useState("未登入");
const [會員, set_會員] = useState("未選擇");
const [權限, set_權限] = useState("無權限");
const member_login = async() => {
  await Axios.post("https://pos-back-end.pktriot.net/point_login", {
    密碼: 密碼,
  }).then( (response) => {

    set_使用者(response.data.名稱); 
    set_權限(response.data.權限); 
    read_punch(response.data.名稱) ; member_staff_read(response.data.名稱)
  }
);

}

//#endregion
//#region 初始日期模組
  const currentDate = new Date() 
  if (currentDate.getHours() < 7)  { currentDate.setDate(currentDate.getDate() - 1) } 
  const 日期 = moment(currentDate).format("YYYY-MM-DD")
 
//#endregion
//#region 管理登入狀態
  const location = useLocation()
  if (location.pathname!=="/" && 使用者==="未登入" && location.pathname!=="/TESTING") {window.location.href = "/"} // 如果目前頁面的網址沒有 密碼 參數，就跳轉到 Home 頁面 
  else if (location.pathname==="/" && 使用者==="未登入") {return(
    <>
<NavBar adminvalue={[日期,使用者,權限]}/>
<div class="home-body">
<div class="home-login-container">
	<img src={img_Banner}   class="home-logo" />
  <h1 class="home-h1">登入</h1>
  <h2 class="home-h2">請輸入使用者授權碼</h2>
  <input class="home-input"  onChange={(e) => {set_密碼(e.target.value)}} />
  <Button class="home-button" loading={loadings[0]} onClick={() => {member_login();enterLoading(0)}}> 驗證授權碼 </Button>
</div>
</div>




    </>
    
  )}
  else if (location.pathname==="/" && 權限==="夥伴") {return(
    <>
       <Space direction="vertical" >
          <NavBar adminvalue={[日期,使用者,權限]}/>

          <Card style={{ width: 300, margin:15}} >
            <Link to ='時數系統_夥伴'>⏲️時數系統</Link> 
            <br/>
            <Link to ='額度系統'>ℹ️額度系統</Link> 
            <br/>
            <br/>
            <a href="/">🟠登出</a> 
          </Card>

      </Space>
    </>
  )}
  else if (location.pathname==="/" && 權限==="門市") {return(
    <>
       <Space direction="vertical" >
          <NavBar adminvalue={[日期,使用者,權限]}/>

          <Space direction="horizontal" >
            <Card title="ℹ️打卡系統" extra={<a href="/">登出</a>} style={{ height:300 ,margin:15 }}>

            <Form name="打卡" form={form} onFinish={(formdata)=>{
              create_punch(formdata);
              set_activeKey("0");
              form.resetFields(['正常時數'])}} style={{ margin:15 }} >

            <Space direction="vertical" >
              <Space direction="horizontal" >
                  <Form.Item name="打卡類型" rules={[{ required: true, message: '必填欄位' }]} ><Radio.Group defaultValue="上班" buttonStyle="solid">
                    <Radio.Button value="🟢上班">🟢上班</Radio.Button>
                    <Radio.Button value="🔴下班">🔴下班</Radio.Button>
                    <Radio.Button value="🟡補卡">🟡補卡</Radio.Button>
                  </Radio.Group></Form.Item>
              </Space>

            <Space direction="horizontal" >
              <Form.Item name="夥伴" rules={[{ required: true, message: '必填欄位' }]} >
                    <Select style={{width:200}}  placeholder="打卡夥伴" suffixIcon={<UserOutlined/>}  options={member_staff_list} onChange={(value)=>{set_會員(value)}} /> 
              </Form.Item>

              <Form.Item name="正常時數" rules={[{ required: false, message: '必填欄位' }]} >
                <Input style={{ width:70 }} placeholder="時數"   type="number" />
              </Form.Item>
            </Space>
              
            <Space direction="horizontal" >
              <Form.Item name="備註"  rules={[{ required: false, message: '必填欄位' }]} >
                <Input style={{ width:200 }} placeholder="備註(特殊事由)"   type="text" />
              </Form.Item>  
              <Form.Item><Button type="primary" htmlType="submit">打卡</Button></Form.Item> 
            </Space>


            </Space>

            </Form>
            
            </Card>
          
          </Space>




          <Card style={{ width: 700,margin:15 }} >
          <Table  size="small" columns={columns_打卡系統}  dataSource={punch_list} rowKey={(record) => record.ID}/>
          </Card>
         




        </Space>
    </>
  )}
  else if (location.pathname==="/" && 權限==="經理人") {return(
    <>
       <Space direction="vertical" >
          <NavBar adminvalue={[日期,使用者,權限]}/>

          <Card style={{ width: 300, margin:15}} >
             <Link to ='金流系統_經理人'>💰金流系統</Link> 
            <br/>
            <a href="/">🟠登出</a> 
          </Card>

      </Space>
    </>
  )}
  else if (location.pathname==="/" && 權限==="東源豐") {return(
    <>
       <Space direction="vertical" >
          <NavBar adminvalue={[日期,使用者,權限]}/>

          <Card style={{ width: 300, margin:15}} >
          <Link to ='時數系統'>📆時數系統</Link> 
            <br/>
            <a href="/">🟠登出</a> 
          </Card>

      </Space>
    </>
  )}
  else if (location.pathname==="/" && 權限==="人資") {return(
    <>
       <Space direction="vertical" >
          <NavBar adminvalue={[日期,使用者,權限]}/>

          <Card style={{ width: 300, margin:15}} >
             <Link to ='考勤系統'>📆考勤系統</Link> 
            <br/>
             <Link to ='品項系統'>♾️品項系統</Link> 
            <br/>
            <a href="/">🟠登出</a> 
          </Card>

      </Space>
    </>
  )}
  else if (location.pathname==="/" && 權限==="總部") {return(
    <>
       <Space direction="vertical" >
          <NavBar adminvalue={[日期,使用者,權限]}/>

          <Card style={{ width: 300, margin:15}} >
            <Link to ='時數系統'>📆時數系統</Link> 
            <br/>
            <Link to ='金流系統'>💰金流系統</Link> 
            <br/>
            <Link to ='給付系統'>💸給付系統</Link> 
            <br/>
            <Link to ='營業概況'>📊營業概況</Link> 
            <br/>
            <a href="/">🟠登出</a> 
          </Card>

      </Space>
    </>
  )}
  else if (location.pathname==="/" && 權限==="股東") {return(
    <>
       <Space direction="vertical" >
          <NavBar adminvalue={[日期,使用者,權限]}/>

          <Card style={{ width: 300, margin:15}} >
            <Link to ='報表系統'>📊報表系統</Link> 
            <br/>
            <br/>
            <a href="/">🟠登出</a> 
          </Card>

      </Space>
    </>
  )}
  else if (location.pathname==="/" && 權限===undefined) {return(
    <>
       <Space direction="vertical" >
          <NavBar adminvalue={[日期,使用者,權限]}/>

          <Card style={{ width: 300,margin:15 }} >
            <a href="/">重新登入</a> 
          </Card>

      </Space>
    </>
  )}

//#endregion
//#region 路由器設定
else return (
    <>
      <NavBar adminvalue={[日期,使用者,權限]}/>
      <div>
        <Routes>
          <Route path="/考勤系統"  element={<考勤系統 adminvalue={[日期,使用者]} />} />
          <Route path="/金流系統_經理人"  element={<金流系統_經理人 adminvalue={[日期,使用者]} />} />

          <Route path="/時數系統_夥伴"  element={<時數系統_夥伴 adminvalue={[日期,使用者]} />} />
          <Route path="/額度系統"  element={<額度系統 adminvalue={[日期,使用者]} />} />

          <Route path="/本日帳務"  element={<本日帳務 adminvalue={[日期,使用者]} />} />
          <Route path="/查詢報表"  element={<查詢報表 adminvalue={[日期,使用者]} />} />
          <Route path="/查詢會員"  element={<查詢會員 adminvalue={[日期,使用者]} />} />

          <Route path="/報表系統"  element={<報表系統 adminvalue={[日期,使用者]} />} />

          <Route path="/金流系統"  element={<金流系統 adminvalue={[日期,使用者]} />} />
          <Route path="/時數系統"  element={<時數系統 adminvalue={[日期,使用者]} />} />
          <Route path="/給付系統"  element={<給付系統 adminvalue={[日期,使用者]} />} />

          <Route path="/品項系統"  element={<品項系統 adminvalue={[日期,使用者]} />} />

          <Route path="/TESTING"  element={<給付系統 adminvalue={[日期,使用者]} />} />
          


        </Routes>
      </div>
    </>
  );
//#endregion

}








function App() {


return (
  <BrowserRouter>
     <Main />
  </BrowserRouter>
);


}

export default App