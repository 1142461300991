// #region import Zone
  
import Paper from '@mui/material/Paper';
import 'antd/dist/reset.css';
import { RadarChartOutlined,RedoOutlined ,MinusCircleOutlined,PlusCircleOutlined,UserOutlined,BankFilled,SignalFilled,DropboxCircleFilled,DollarCircleFilled,SwapOutlined,CheckOutlined,SearchOutlined,RetweetOutlined,InfoCircleOutlined } from '@ant-design/icons';
import { Space , Button, Select, Table , Input , DatePicker, InputNumber , Tabs  , Progress,Typography,Descriptions,Form,Divider, Row ,Popover ,Col} from "antd";
import { useRef,useState,useEffect } from "react";
import Axios from "axios";
import moment from 'moment';
import React from 'react';

// #endregion

export function 報表系統(adminvalue){

const Tab_items = [
    {key: '0',label: (<span><RadarChartOutlined />分析</span>),
    children: <span><營業頁面 adminvalue={[adminvalue.adminvalue[0],adminvalue.adminvalue[1]]} /></span>,
    },
    {key: '1',label:  (<span><DollarCircleFilled />金流</span>),
    children: <span><金流頁面 adminvalue={[adminvalue.adminvalue[0],adminvalue.adminvalue[1]]} /></span>,
    }
    ,
    {key: '3',label: (<span><BankFilled />出勤</span>),
    children: <span><出勤頁面 adminvalue={[adminvalue.adminvalue[0],adminvalue.adminvalue[1]]} /></span>,
    } 
  ]

return (<div className="App"> 
  <Space direction='vertical'>
    <Tabs   size="large" type="card" style={{ marginLeft: "20px" ,  fontWeight: "bold", borderRadius: "5px"}} defaultActiveKey="0" items={Tab_items}  />
  </Space>
  
</div>

)}



function 營業頁面(adminvalue)
{
//#region 初始日期與門市模組
const currentDate = new Date()  //currentDate.setDate(currentDate.getDate() - 15) 
const 今天 = moment(currentDate).format("YYYY-MM-DD")
const 前幾天 = moment(currentDate).subtract(1, 'days').format("YYYY-MM-DD")
const initial_object = {起始日:前幾天,結束日:今天}
//#endregion
//#region PROGRESS 模組
const [percent, setPercent] = useState(0);
const [percent_progessmsg, setpercent_progessmsg] = useState("選門市/區間");
//#endregion
//#region 品項 模組(R)
const [item_list , set_item_list] = useState([]);
const read_item = async() => {
  let response = await Axios.get("https://pos-back-end.pktriot.net/item_read") 
  const newarray = response.data.map((item) => {return {value:item.items_name,label:item.items_name ,}})
  set_item_list(newarray)
}
//#endregion
//#region 存貨 模組
const [dayinventory_list , set_dayinventory_list] = useState([]);
const read_dayinventory = async(form_object) => {
  const 起始日 = new Date(form_object.起始日);
  const 報表起始日 = new Date(起始日.setDate(起始日.getDate() - 1)).toLocaleString('zh-TW', {year: 'numeric', month: '2-digit', day: '2-digit',})
  let response = await Axios.put("https://pos-back-end.pktriot.net/dayinventory_read_pointall",{
    種類: form_object.種類,
    起始日: 報表起始日,
    結束日: form_object.結束日,
})
  const sortedData = response.data.sort((a, b) => new Date(b.日期) - new Date(a.日期));
  set_dayinventory_list(sortedData)
}
//#endregion 
//#region 會員 模組(R)
const [member_list_point , set_member_point_list] = useState([]);
const read_member = async() => {
  let response = await Axios.post("https://pos-back-end.pktriot.net/grdxkAYF", {
    門市: adminvalue.adminvalue[1],
  })
  const newarray = response.data.map((item) => {return {value:item.名稱,label:item.名稱 ,}})
  set_member_point_list(newarray)

}
//#endregion
//#region 物流 模組

const [logistic_list_summary, set_logistic_list_summary] = useState([]);
const read_logistic_summary = async (form_object) => {
  let response = await Axios.put("https://pos-back-end.pktriot.net/logistic_read_point",{
    門市: form_object.門市,
    起始日: form_object.起始日,
    結束日: form_object.結束日,
})
  Object.keys(response.data).forEach((key) => {const timestamp = response.data[key].TIMESTAMP_ID;response.data[key].TIMESTAMP_ID = new Date(timestamp).toLocaleString('zh-TW', {hour12: false});});
  set_logistic_list_summary(response.data) 
}

//#endregion 
//#region LOGISTIC_ITEM 模組

const [logistic_list_item, set_logistic_list_item] = useState([]);
const read_logistic_item = async (form_object) => {
  let response = await Axios.put("https://pos-back-end.pktriot.net/logistic_read_item",{
    物品: form_object.種類,
    起始日: form_object.起始日,
    結束日: form_object.結束日,
})
  Object.keys(response.data).forEach((key) => {const timestamp = response.data[key].TIMESTAMP_ID;response.data[key].TIMESTAMP_ID = new Date(timestamp).toLocaleString('zh-TW', {hour12: false});});
  set_logistic_list_item(response.data) 
}

  //#endregion 
//#region 日報 模組
const [report_list_summary , set_report_list_summary] = useState([]);
const [report_list_check , set_report_list_check] = useState([]);
const read_report_summary = async (form_object) => {
  const 起始日 = new Date(form_object.起始日);
  const 報表起始日 = new Date(起始日.setDate(起始日.getDate() - 1)).toLocaleString('zh-TW', {year: 'numeric', month: '2-digit', day: '2-digit',})
  let response = await Axios.put("https://pos-back-end.pktriot.net/dayreport_read_shareholder",{
    門市: form_object.門市,
    起始日: 報表起始日,
    結束日: form_object.結束日,
  })
  Object.keys(response.data).forEach((key) => {const timestamp = response.data[key].日期;response.data[key].日期 = new Date(timestamp).toLocaleString('zh-TW', {hour12: false});});
  const sortedData = response.data.sort((a, b) => new Date(b.DAYREPORT_ID) - new Date(a.DAYREPORT_ID));
  set_report_list_summary(sortedData)
  
}






const [台幣總值 , set_台幣總值] = useState("-");
const [等價總值 , set_等價總值] = useState("-");
const [刮樂總值 , set_刮樂總值] = useState("-");
const [仟元總量 , set_仟元總量] = useState("-");

const read_report_check = async (form_object) => {
  let response = await Axios.put("https://pos-back-end.pktriot.net/dayreport_read_check",{
    起始日: form_object.起始日,
    結束日: form_object.結束日,
  })
  Object.keys(response.data).forEach((key) => {const timestamp = response.data[key].日期;response.data[key].日期 = new Date(timestamp).toLocaleString('zh-TW', {year: 'numeric',month: '2-digit',day: '2-digit'});});
  const sortedData = response.data.sort((a, b) => new Date(b.DAYREPORT_ID) - new Date(a.DAYREPORT_ID));
  set_report_list_check(sortedData)



  const 台幣總值_cal = sortedData.reduce((accumulator, currentItem) => {return accumulator + (currentItem.台幣現值|| 0) },0).toLocaleString();
  const 等價總值_cal = sortedData.reduce((accumulator, currentItem) => {return accumulator + (currentItem.等價現值|| 0) },0).toLocaleString(); 
  const 刮樂總值_cal = sortedData.reduce((accumulator, currentItem) => {return accumulator + (currentItem.刮樂現值|| 0) },0).toLocaleString(); 
  const 仟元總量_cal = sortedData.reduce((accumulator, currentItem) => {return accumulator + (currentItem.現金1000|| 0) },0).toLocaleString();  

  set_台幣總值(台幣總值_cal)
  set_等價總值(等價總值_cal)
  set_刮樂總值(刮樂總值_cal)
  set_仟元總量(仟元總量_cal)

  
  
}
//#endregion
//#region SUMMARY 模組
const [summary_list , set_summary_list] = useState([]);
const [台彩總計 , set_台彩總計] = useState("-");
const [運彩總計 , set_運彩總計] = useState("-");
const [刮樂總計 , set_刮樂總計] = useState("-");
const [毛利總計 , set_毛利總計] = useState("-");

useEffect(() => { if (report_list_summary.length > 0) { 


      var summarylist = []
      const listLength = report_list_summary.length

      for (let i = 0; i < listLength-1 ; i++) {

        const currentDate = new Date(report_list_summary[i+1].日期); currentDate.setHours(currentDate.getHours() + 25);
        const 日期 = currentDate.toLocaleString('zh-TW', {year: 'numeric',month: '2-digit',day: '2-digit'}).replace(/\//g, "-")
        const 門市 = report_list_summary[i].門市
        
        const 物流增加 = logistic_list_summary
                      .filter((item)=>{ const formattedDate = new Date(item.TIMESTAMP_ID).toLocaleDateString("zh-TW", {year: "numeric",month: "2-digit", day: "2-digit",}).replace(/\//g, "-"); return formattedDate === 日期  })
                      .reduce((accumulator, currentItem) => {return accumulator + (currentItem.額度增加|| 0) },0)
        const 物流減少 = logistic_list_summary
                      .filter((item)=>{ const formattedDate = new Date(item.TIMESTAMP_ID).toLocaleDateString("zh-TW", {year: "numeric",month: "2-digit", day: "2-digit",}).replace(/\//g, "-"); return formattedDate === 日期  })
                      .reduce((accumulator, currentItem) => {return accumulator + (currentItem.額度減少|| 0) },0)   


        const 台彩銷售_cal = report_list_summary[i].台彩銷售
        const 運彩銷售_cal = report_list_summary[i].運彩銷售        
        const 刮樂銷售_cal = report_list_summary[i+1].刮樂現值-report_list_summary[i].刮樂現值+物流增加-物流減少 ; 
        const 預估毛利_cal = (台彩銷售_cal+刮樂銷售_cal)*0.08+運彩銷售_cal*0.04 

        const 台彩銷售 = Math.floor(台彩銷售_cal).toLocaleString();
        const 運彩銷售 = Math.floor(運彩銷售_cal).toLocaleString(); 
        const 刮樂銷售 = Math.floor(刮樂銷售_cal).toLocaleString();
        const 預估毛利 = Math.floor(預估毛利_cal).toLocaleString();

        summarylist.push({
          DAYREPORT_ID: report_list_summary[i].DAYREPORT_ID,
          日期: 日期,
          門市: 門市,

          台彩銷售:台彩銷售,
          運彩銷售:運彩銷售,
          刮樂銷售:刮樂銷售,
          預估毛利:預估毛利,

          台彩銷售_cal:台彩銷售_cal,
          運彩銷售_cal:運彩銷售_cal,
          刮樂銷售_cal:刮樂銷售_cal,
          預估毛利_cal:預估毛利_cal,

      
        })}

      set_summary_list(summarylist);setPercent(100)
      
      const 台彩總計_cal = summarylist.reduce((accumulator, currentItem) => {return accumulator + (currentItem.台彩銷售_cal|| 0) },0).toLocaleString(); 
      const 運彩總計_cal = summarylist.reduce((accumulator, currentItem) => {return accumulator + (currentItem.運彩銷售_cal|| 0) },0).toLocaleString();
      const 刮樂總計_cal = summarylist.reduce((accumulator, currentItem) => {return accumulator + (currentItem.刮樂銷售_cal|| 0) },0).toLocaleString();
      const 毛利總計_cal = Math.floor(summarylist.reduce((accumulator, currentItem) => {return accumulator + (currentItem.預估毛利_cal|| 0) },0)).toLocaleString();
      
      set_台彩總計(台彩總計_cal)
      set_運彩總計(運彩總計_cal)
      set_刮樂總計(刮樂總計_cal)
      set_毛利總計(毛利總計_cal)

      setpercent_progessmsg("分析完成...")

    }

}, [report_list_summary]);
//#endregion
//#region SUMMARY_INSTANT 模組
const [專用起始日 , set_專用起始日] = useState("-");  
const [專用結束日 , set_專用結束日] = useState("-");  
const [summary_instant_list , set_summary_instant_list] = useState([]);
  const [全部加總 , set_全部加總] = useState("-");    
  const [金龍加總 , set_金龍加總] = useState("-");
  const [成功加總 , set_成功加總] = useState("-");
  const [康樂加總 , set_康樂加總] = useState("-");
  const [後龍加總 , set_後龍加總] = useState("-");

  useEffect(() => { if (dayinventory_list.length > 0) {
    
      var summarylist = []
      
      // 假設你有兩個日期物件 date1 和 date2
      let date1 = new Date(專用起始日);
      let date2 = new Date(專用結束日);


      // 取得兩個日期的時間戳（以毫秒為單位）
      let timestamp1 = date1.getTime();
      let timestamp2 = date2.getTime();



      // 計算兩個時間戳的差值（以毫秒為單位）
      let differenceInMilliseconds = timestamp2 - timestamp1;

      // 將毫秒轉換為天數（1天 = 24小時 = 1440分鐘 = 86400秒 = 86400000毫秒）
      let differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);

      // 由於天數可能會有小數，所以我們取整數部分
      differenceInDays = Math.floor(differenceInDays);
      
      

      for (let i = 0; i < differenceInDays+1 ; i++) {
        
        let 迴圈起始日 = new Date(專用起始日); 
        let 當前日期= new Date(迴圈起始日.setDate(迴圈起始日.getDate() + i)).toLocaleString('zh-TW', {year: 'numeric', month: '2-digit', day: '2-digit',}).replace(/\//g, "-");
        let 迴圈昨日日 = new Date(當前日期)
        let 昨日日期 = new Date(迴圈昨日日.setDate(迴圈昨日日.getDate() -1 )).toLocaleString('zh-TW', {year: 'numeric', month: '2-digit', day: '2-digit',}).replace(/\//g, "-");
        var obj = { ID: i , 日期: 當前日期, };  

        

        for (let k = 0; k < member_list_point.length ; k++) {
          let 昨日庫存 = dayinventory_list
                        .filter((item)=>{
                        const formattedDate = new Date(item.日期).toLocaleDateString("zh-TW", {year: "numeric",month: "2-digit", day: "2-digit",}).replace(/\//g, "-"); 
                        return formattedDate === 昨日日期 && item.門市 === member_list_point[k].value;
                        })
                        .reduce((accumulator, currentItem) => {return accumulator + (currentItem.數量|| 0) },0);
          let 今日庫存 = dayinventory_list
                        .filter((item)=>{
                            const formattedDate = new Date(item.日期).toLocaleDateString("zh-TW", {year: "numeric",month: "2-digit", day: "2-digit",}).replace(/\//g, "-"); 
                            return formattedDate === 當前日期 && item.門市 === member_list_point[k].value;
                        })
                        .reduce((accumulator, currentItem) => {return accumulator + (currentItem.數量|| 0) },0);
          let 物流增加 = logistic_list_item
                        .filter((item)=>{
                            const formattedDate = new Date(item.TIMESTAMP_ID).toLocaleDateString("zh-TW", {year: "numeric",month: "2-digit", day: "2-digit",}).replace(/\//g, "-"); 
                            return formattedDate === 當前日期 && item.門市 === member_list_point[k].value;
                        })
                        .reduce((accumulator, currentItem) => {return accumulator + (currentItem.存貨增加|| 0) },0);
          let 物流減少 = logistic_list_item
                        .filter((item)=>{
                            const formattedDate = new Date(item.TIMESTAMP_ID).toLocaleDateString("zh-TW", {year: "numeric",month: "2-digit", day: "2-digit",}).replace(/\//g, "-"); 
                            return formattedDate === 當前日期 && item.門市 === member_list_point[k].value;
                        })
                        .reduce((accumulator, currentItem) => {return accumulator + (currentItem.存貨減少|| 0) },0);

          let result = 昨日庫存 - 今日庫存 + 物流增加 - 物流減少;

          obj[member_list_point[k].value] = result;
         
          
        }
        obj['全部'] = obj[member_list_point[0].value]+obj[member_list_point[1].value]+obj[member_list_point[2].value]+obj[member_list_point[3].value]

        summarylist.push(obj);
      }


      
      const 金龍 = summarylist.reduce((accumulator, currentItem) => {return accumulator + (currentItem['PO-內湖金龍']|| 0) },0).toLocaleString();
      const 成功 = summarylist.reduce((accumulator, currentItem) => {return accumulator + (currentItem['PO-內湖成功']|| 0) },0).toLocaleString();  
      const 康樂 = summarylist.reduce((accumulator, currentItem) => {return accumulator + (currentItem['PO-內湖康樂']|| 0) },0).toLocaleString(); 
      const 後龍 = summarylist.reduce((accumulator, currentItem) => {return accumulator + (currentItem['PO-後龍中華']|| 0) },0).toLocaleString();
      const 全部 = summarylist.reduce((accumulator, currentItem) => {return accumulator + (currentItem['全部']|| 0) },0).toLocaleString();  


      set_金龍加總(金龍)
      set_成功加總(成功)
      set_康樂加總(康樂)
      set_後龍加總(後龍)
      set_全部加總(全部)

      set_summary_instant_list(summarylist);setPercent(100)

      setpercent_progessmsg("分析完成...")
      
      }

    }, [dayinventory_list],[logistic_list_item]) 

//#endregion

//#region Table 模組
const columns_summary = [
  {
      Key:'0',
      title:'日期',
      dataIndex:'日期', width: 110
    },
    {
      Key:'1',
      title:'門市',
      dataIndex:'門市', width: 120
    },
    {
      Key:'2',
      title:'台彩',
      dataIndex:'台彩銷售',width: 80
    },
    {
      Key:'3',
      title:'運彩',
      dataIndex:'運彩銷售',width: 80
    },
    {
      Key:'4',
      title:'刮樂',
      dataIndex:'刮樂銷售',width: 80
    },
];
//#endregion
//#region LOAD 模組
const preloadData = async () => {
  await read_member()
  await read_item()
  await read_report_check(initial_object)
}
useEffect(() => {preloadData()}, [])



const loadData = async (form_object) => { 
  
  setPercent(10);setpercent_progessmsg("初始化中...")
  await read_logistic_summary(form_object)
  setPercent(30);setpercent_progessmsg("讀取報表...")
  await read_report_summary(form_object)
  setPercent(50);setpercent_progessmsg("分析整合中...")
}

const loadData2 = async (form_object) => { 
  set_專用起始日(form_object.起始日)
  set_專用結束日(form_object.結束日)
  setPercent(10);setpercent_progessmsg("初始化中...")
  await read_logistic_item(form_object)
  setPercent(30);setpercent_progessmsg("讀取報表...")
  await read_dayinventory(form_object)
  setPercent(50);setpercent_progessmsg("分析整合中...")
}

// #endregion


  

  return (
<div> 
      <Form name="查詢報表" onFinish={(form_object) => loadData(form_object)}>
      <Space direction='horizontal'>

        <Paper elevation={3}  style={{ width: 95,height:150,margin:15 }} >
        <Divider />
          <Space direction='horizontal' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center',margin:10 }} >
            <Progress type="circle" percent={percent} size={45} />
          </Space>
        <Divider />
          <Space direction='horizontal' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}} >
          <Descriptions.Item >{percent_progessmsg}</Descriptions.Item>
          </Space>
        </Paper>

        <Space direction='vertical'>

          <Space direction='horizontal'>
            <Form.Item name="BUTTON" rules={[{ required: false, message: '必填欄位' }]}  >
              <Button type="primary" shape="circle" htmlType="submit" icon={<SearchOutlined />} />
            </Form.Item>
            <Form.Item name="門市" rules={[{ required: true, message: '必填欄位' }]}  >
              <Select showSearch style={{width: 125}} suffixIcon={<UserOutlined/>} placeholder="門市" optionFilterProp="children" filterOption={(input, option) =>(option?.label ?? "").includes(input)}
              filterSort={(optionA, optionB) =>(optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())} options={member_list_point} />
            </Form.Item>
            <Form.Item name="起始日" rules={[{ required: true, message: '必填欄位' }]}  >
              <DatePicker  format="YYYY-MM-DD" placeholder="起始日"style={{width: 125}} />
            </Form.Item>
            <Form.Item name="結束日" rules={[{ required: true, message: '必填欄位' }]} >
              <DatePicker  format="YYYY-MM-DD" placeholder="結束日" style={{width: 125}} />
            </Form.Item>
              
          </Space>

          <Space direction='horizontal'>
            <Paper elevation={3}  style={{ width: 140,height:40,margin:1, display: 'flex', justifyContent: 'center', alignItems: 'center' }} >總計</Paper>
            <Paper elevation={3}  style={{ width: 90,height:40,margin:1, display: 'flex', justifyContent: 'center', alignItems: 'center' }} >{台彩總計}</Paper>
            <Paper elevation={3}  style={{ width: 90,height:40,margin:1, display: 'flex', justifyContent: 'center', alignItems: 'center' }} >{運彩總計}</Paper>
            <Paper elevation={3}  style={{ width: 90,height:40,margin:1, display: 'flex', justifyContent: 'center', alignItems: 'center' }} >{刮樂總計}</Paper>
          </Space>

        </Space>

      </Space>

      </Form>


    

      <Table  columns={columns_summary}  dataSource={summary_list} rowKey={(record) => record.DAYREPORT_ID}/>
    </div>
    
)}

function 金流頁面(adminvalue)
{
//#region Pre-Load 模組
const loadData = async () => { await read_member()} ; useEffect(() => {loadData()}, [])
//#endregion
//#region Member 模組
const [member_list , set_member_list] = useState([]);
const read_member = async() => {
  let response = await Axios.post("https://pos-back-end.pktriot.net/CDyAQdvg", {
    門市: adminvalue.adminvalue[1],
  })
  const newarray = response.data.map((item) => {return {value:item.名稱,label:item.名稱 ,}})
  set_member_list(newarray);
}

//#endregion
//#region Cashflow 模組
const [cashflow_list, set_cashflow_list] = useState([]);

const read_cashflow = async (form_object) => {
  let response = await Axios.put("https://pos-back-end.pktriot.net/cashflow_read_member",{
    門市: "股東",
    會員: form_object.會員,
    起始日: form_object.起始日,
    結束日: form_object.結束日,
  })

  Object.keys(response.data)
  .forEach((key) => {const timestamp = response.data[key].TIMESTAMP_ID;response.data[key].TIMESTAMP_ID = new Date(timestamp)
  .toLocaleString('zh-TW', {hour12: false})
  .replace(/\//g, "-")})

  set_cashflow_list(response.data)  
}
//#endregion
//#region Table Search 模組
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div style={{padding: 8,}} onKeyDown={(e) => e.stopPropagation()} >
            <Input ref={searchInput} placeholder={`Search ${dataIndex}`} value={selectedKeys[0]} style={{ marginBottom: 8, display: 'block', }}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])} onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            />
            <Space>
            <Button type="primary" onClick={() => handleSearch(selectedKeys, confirm, dataIndex)} icon={<SearchOutlined />} size="small" style={{ width: 90, }} >搜尋</Button>
            <Button onClick={() => {clearFilters && handleReset(clearFilters);confirm({ closeDropdown: false, }); setSearchText(selectedKeys[0]); setSearchedColumn(dataIndex); }} size="small" style={{ width: 90, }} >重置</Button>
            </Space>
            </div>
        ),

        filterIcon: (filtered) => (
        <SearchOutlined  style={{ color: filtered ? '#1890ff' : undefined, }} />
        ),

        onFilter: (value, record) =>
        (record[dataIndex] ? record[dataIndex].toString() : '')
        .toLowerCase()
        .includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => { if (visible) { setTimeout(() => searchInput.current?.select(), 100); } },

        render: (text) => ( text ),
  });
// #endregion  
//#region Table 模組
const columns_cashflow = [
  {
      Key:'0',
      title:'交易時間',
      dataIndex:'TIMESTAMP_ID',
      ...getColumnSearchProps('TIMESTAMP_ID'),
      render: (text, record) => {return < span style={{ color: '#0F3B53' , fontSize: '10px' }}>{text}</span>}
    },
    {
      Key:'4',
      title:'交易類型',
      dataIndex:'交易類型',
      ...getColumnSearchProps('交易類型'),
      render: (text, record) => {return < span style={{ color: '#0F3B53' , fontSize: '10px' }}>{text}</span>}
    },
    {
      Key:'5',
      title:'額度減少',
      dataIndex:'額度減少',
      ...getColumnSearchProps('額度減少'),
  
      render: (text, record) => {return <strong style={{ color: '#4285f4' , fontSize: '10px' }}>{text}</strong>}
    },
    {
      Key:'6',
      title:'額度增加',
      dataIndex:'額度增加',
      ...getColumnSearchProps('額度增加'),
  
      render: (text, record) => {return <strong style={{ color: '#EB6472' , fontSize: '10px' }}>{text}</strong>}
    },
    {
      Key:'7',
      title:'備註',
      dataIndex:'備註',
      ...getColumnSearchProps('備註'),

      render: (text, record) => {return < span style={{ color: '#0F3B53' , fontSize: '10px' }}>{text}</span>}
    },
    {
      Key:'8',
      title:'餘額',
      dataIndex:'餘額',
      render: (text, record) => {return <strong style={{ color: '#FFC14D' , fontSize: '10px' }}>{text}</strong>}
    },
];
//#endregion



  return (
    <div><Paper elevation={3}  style={{ width: 635 }} >
      <Form name="查詢區間_金流"  initialValues={{remember: true}} onFinish={read_cashflow}>
        <Space direction='horizontal' style={{ marginTop:15,marginLeft:15 }}>
        <Form.Item name="BUTTON" rules={[{ required: false, message: '必填欄位' }]}  >
          <Button type="primary" shape="circle" htmlType="submit" icon={<SearchOutlined />} />
        </Form.Item>
        <Form.Item name="會員" rules={[{ required: true, message: '必填欄位' }]}  style={{ marginLeft: 0 }}>
          <Select showSearch style={{width: 125}} suffixIcon={<UserOutlined/>} placeholder="會員" optionFilterProp="children" filterOption={(input, option) =>(option?.label ?? "").includes(input)}
          filterSort={(optionA, optionB) =>(optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())} options={member_list} />
        </Form.Item>
        <Form.Item name="起始日" rules={[{ required: true, message: '必填欄位' }]}  style={{ marginLeft: 0 }}>
          <DatePicker  format="YYYY-MM-DD" placeholder="起始日"  style={{width: 125}} />
        </Form.Item>
        <Form.Item name="結束日" rules={[{ required: true, message: '必填欄位' }]}  style={{ marginLeft: 0 }}>
          <DatePicker  format="YYYY-MM-DD" placeholder="結束日"  style={{width: 125}} />
        </Form.Item>
          
        </Space>
      </Form>
      <Table  showExpandColumn={false} expandRowByClick={true}  columns={columns_cashflow}  dataSource={cashflow_list} rowKey={(record) => record.CASHFLOW_ID}/>
    </Paper></div>
  
  
  
  
  )
}

function 出勤頁面(adminvalue)
{
//#region Pre-Load 模組
const loadData = async () => { await read_member()} ; useEffect(() => {loadData()}, [])
//#endregion
//#region Member 模組
const [member_list_point , set_member_point_list] = useState([]);
const [member_list_staff , set_member_staff_list] = useState([]);
const read_member = async() => {
  let response = await Axios.post("https://pos-back-end.pktriot.net/gBYb95SH", {
    門市: adminvalue.adminvalue[1],
  })
  const newarray = response.data.map((item) => {return {value:item.名稱,label:item.名稱 ,}})

  const staff = newarray.filter((item) => item.label.startsWith('ST'));
  set_member_staff_list(staff);

  const point = newarray.filter((item) => item.label.startsWith('PO'));
  set_member_point_list(point)
}

//#endregion

//#region PUNCHCARD 模組(RC)
const [punch_list , set_punch_list] = useState([]);
const read_punch = async(table_object) => {
  let response = await Axios.post("https://pos-back-end.pktriot.net/NBACM_0101L",{
    起始日: table_object.起始日,
    結束日: table_object.結束日,
    門市: table_object.門市,
    夥伴: table_object.夥伴,
  })

  Object.keys(response.data)
  .forEach((key) => {const timestamp = response.data[key].打卡時間;response.data[key].打卡時間 = new Date(timestamp)
  .toLocaleString('zh-TW', {hour12: false})
  .replace(/\//g, "-")})

  const sortedData = response.data.sort((a, b) => new Date(b.打卡時間) - new Date(a.打卡時間));
  set_punch_list(sortedData)

}
const update_punch = async(table_object) => {
  await Axios.post("https://pos-back-end.pktriot.net/NBACM_0103",{
    ID:table_object.ID,
    打卡時間:table_object.打卡時間,
    打卡類型:table_object.打卡類型,
    門市: table_object.門市, 
    夥伴: table_object.夥伴,
    正常時數:table_object.正常時數,
    特殊時數:table_object.特殊時數,
    備註: table_object.備註,
    主管批准作業: table_object.主管批准作業,
  })
  const new_item = {
    ID:table_object.ID,
    打卡時間:table_object.打卡時間,
    打卡類型:table_object.打卡類型,
    門市:table_object.門市,
    夥伴:table_object.夥伴,
    正常時數:table_object.正常時數,
    特殊時數:table_object.特殊時數,
    主管批准作業:table_object.主管批准作業,
    備註:table_object.備註,
  }

  set_punch_list(punch_list.map(item => 
    item.ID === table_object.ID
      ? new_item
      : item
  ));


}
const delete_punch = async(table_object) => {
  await Axios.post("https://pos-back-end.pktriot.net/NBACM_0104",{ ID:table_object.ID, })

  set_punch_list(punch_list.filter(item => item.ID !== table_object.ID ));


}


//#endregion

//#region Table 模組
  const columns_打卡系統 = [
    {
        Key:'0',
        title:'門市',
        dataIndex:'門市',
      },
      {
        Key:'1',
        title:'時間',
        dataIndex:'打卡時間', 
      },
      {
        Key:'2',
        title:'類型',
        dataIndex:'打卡類型', 
      },
      {
        Key:'3',
        title:'夥伴',
        dataIndex:'夥伴',
      },
      {
        Key:'4',
        title:'時數',
        dataIndex:'正常時數',
      },
      {
        Key:'5',
        title:'考勤',
        dataIndex:'考勤',
      },
      {
        Key:'6',
        title:'備註',
        dataIndex:'備註',
      },
      
  ]
//#endregion


//#region 參數項表格
const [form_參數項] = Form.useForm()
const [form_打卡清單] = Form.useForm()
//#endregion

//#region 計算即時
const [總時數, set_總時數] = useState(0)

const calculation = async () => {
  const sum_總時數 = punch_list.reduce((accumulator, currentValue) => {return accumulator + parseFloat(currentValue.正常時數) + parseFloat(currentValue.特殊時數)}, 0);
  set_總時數(sum_總時數) 
}

useEffect(() => {calculation()}, [punch_list])
// #endregion

//#region 結帳狀態模組

return (<div className="App">
<Space direction="horizontal"  align="start">
  <Form name="參數項"  form={form_參數項} onFinish={(data)=>{read_punch(data)}}>
    <Space direction="horizontal" >

      <Paper elevation={3}   >

        <Space direction="vertical" style={{ margin: 15 }}>
          <p style={{ color: '#EB6472' , fontSize: '20px' ,fontWeight: 'bold',textAlign: 'center'}}>參數項<Divider /></p>
          
          <Row>
          <Col flex={1}>
            <Form.Item name="起始日"  rules={[{ required: true, message: '必填欄位' }]}  >
              <DatePicker placeholder={"起始日"} style={{width: 175}} format="YYYY-MM-DD"  />
            </Form.Item>
            <Form.Item name="結束日"  rules={[{ required: true, message: '必填欄位' }]}  >
              <DatePicker placeholder={"結束日"} style={{width: 175}} format="YYYY-MM-DD"  />
            </Form.Item>

            <Form.Item name="門市" rules={[{ required: false, message: '必填欄位' }]}  >
              <Select placeholder={"門市"} showSearch style={{width: 175}}  optionFilterProp="children" filterOption={(input, option) =>(option?.label ?? "").includes(input)}
              filterSort={(optionA, optionB) =>(optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())} options={member_list_point} />
            </Form.Item>

            <Form.Item name="夥伴" rules={[{ required: false, message: '必填欄位' }]}  >
              <Select placeholder={"夥伴"} showSearch style={{width: 175}}  optionFilterProp="children" filterOption={(input, option) =>(option?.label ?? "").includes(input)}
              filterSort={(optionA, optionB) =>(optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())} options={member_list_staff} />
            </Form.Item>

            <Space direction="horizontal" >
            <Form.Item name="清除" >
              <Button  icon={<RedoOutlined />} onClick={() => form_參數項.resetFields(['門市', '夥伴'])} >清除</Button>
            </Form.Item>

            <Form.Item name="查詢" >
              <Button   htmlType="submit" icon={<SearchOutlined /> }>查詢</Button>
            </Form.Item>

            </Space>

          </Col>
          </Row>

        </Space>




      </Paper>

    </Space>  
  </Form>

  <Paper elevation={3}  >

    <Space direction="vertical" style={{ margin: 15 }}>
      <p style={{ color: '#EB6472' , fontSize: '20px' ,fontWeight: 'bold',textAlign: 'center'}}>資料項<Divider /></p>
      <Space direction="vertical" >
        <span>總時數：<InputNumber disabled value={總時數} style={{ width: 85 }} /></span>

      </Space>

    </Space>




  </Paper>

</Space>

<Divider orientation="left">時數表</Divider>
  <Paper elevation={3}  style={{ width: 1000 }} >
    <Table size="small" pagination={{ pageSize: 100 }} columns={columns_打卡系統}  dataSource={punch_list} rowKey={(record) => record.ID}/>
  </Paper>


</div>)

}
