//#region ⭐模組需求套件--------------------------------------------------------
import 'antd/dist/reset.css';
import { WarningOutlined,DollarCircleOutlined,CodeSandboxOutlined,DropboxOutlined,IeOutlined,SearchOutlined } from '@ant-design/icons';
import { UserOutlined,AlertOutlined,LockOutlined,PlusOutlined,DollarOutlined,LoadingOutlined,BarcodeOutlined,CheckOutlined,RedoOutlined } from '@ant-design/icons';
import { Space , Button, Select, Table , Input , Divider, DatePicker , Tabs  , InputNumber,Typography,Descriptions,Form,message, Popconfirm,Row,Col,notification,Statistic  } from "antd";
import { useRef,useState,useEffect } from "react";
import Axios from "axios";
//#endregion

 export function 金流系統_經理人(adminvalue)
{

  const [使用者, set_使用者] = useState(adminvalue.adminvalue[1]);
//#region Table Search 模組
const [searchText, setSearchText] = useState('');
const [searchedColumn, setSearchedColumn] = useState('');
const searchInput = useRef(null);
const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
};
const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
};
const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
        <div style={{padding: 8,}} onKeyDown={(e) => e.stopPropagation()} >
        <Input ref={searchInput} placeholder={`Search ${dataIndex}`} value={selectedKeys[0]} style={{ marginBottom: 8, display: 'block', }}
        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])} onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
        />
        <Space>
        <Button type="primary" onClick={() => handleSearch(selectedKeys, confirm, dataIndex)} icon={<SearchOutlined />} size="small" style={{ width: 90, }} >搜尋</Button>
        <Button onClick={() => {clearFilters && handleReset(clearFilters);confirm({ closeDropdown: false, }); setSearchText(selectedKeys[0]); setSearchedColumn(dataIndex); }} size="small" style={{ width: 90, }} >重置</Button>
        </Space>
        </div>
    ),

    filterIcon: (filtered) => (
    <SearchOutlined  style={{ color: filtered ? '#1890ff' : undefined, }} />
    ),

    onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => { if (visible) { setTimeout(() => searchInput.current?.select(), 100); } },

    render: (text) => ( text ),
});
// #endregion  


const [member_list , set_member_list] = useState([]);
const read_member = async() => {
  let response = await Axios.put("https://pos-back-end.pktriot.net/member_read",{門市: 使用者,})
  const newarray = response.data.map((item) => {return {value:item.名稱,label:item.名稱 ,}})
  const guest = newarray.filter((item) => 
  item.label.startsWith('PO')
  );
  guest.sort((a, b) => { if (a.label.match(/^\d+/)) {  return parseInt(a.label) - parseInt(b.label); } else { return a.label.localeCompare(b.label); }  });

  set_member_list(guest);
}







const loadData = async () => {
  await read_member()
}
useEffect(() => {loadData()}, [])

const columns_cashflow = [
  {
      Key:'0',width: 180,
      title:'交易時間',
      dataIndex:'TIMESTAMP_ID',
      ...getColumnSearchProps('TIMESTAMP_ID'),
    },
    {
      Key:'1',width: 160,
      title:'會員',
      dataIndex:'會員',
      ...getColumnSearchProps('會員'),
    },
    {
      Key:'2',width: 160,
      title:'門市',
      dataIndex:'門市',
      ...getColumnSearchProps('門市'),
    },
    {
      Key:'3',
      title:'承辦',width: 160,
      dataIndex:'承辦',
      ...getColumnSearchProps('承辦'),
    },
    {
      Key:'4',
      title:'交易類型',width: 210,
      dataIndex:'交易類型',
      ...getColumnSearchProps('交易類型'),
    },
    {
      Key:'5',
      title:'額度減少',width: 160,
      dataIndex:'額度減少',
      ...getColumnSearchProps('額度減少'),
  
      render: (text, record) => {return <strong style={{ color: '#4285f4' , fontSize: '20px' }}>{text}</strong>}
    },
    {
      Key:'6',
      title:'額度增加',width: 160,
      dataIndex:'額度增加',
      ...getColumnSearchProps('額度增加'),
  
      render: (text, record) => {return <strong style={{ color: '#EB6472' , fontSize: '20px' }}>{text}</strong>}
    },
    {
      Key:'7',
      title:'備註',width: 200,
      dataIndex:'備註',
      ...getColumnSearchProps('備註'),

      render: (text, record) => {return < span style={{ color: '#0F3B53' , fontSize: '10px' }}>{text}</span>}
    },
];

const [cashflow_list_point, set_cashflow_list_point] = useState([]);
const read_cashflow_point = async (form_object) => {
  let response = await Axios.put("https://pos-back-end.pktriot.net/cashflow_read_point_LOCK",{
    門市: form_object.門市,
    起始日: form_object.起始日,
    結束日: form_object.結束日,
  })

  Object.keys(response.data)
  .forEach((key) => {const timestamp = response.data[key].TIMESTAMP_ID;response.data[key].TIMESTAMP_ID = new Date(timestamp)
  .toLocaleString('zh-TW', {hour12: false})
  .replace(/\//g, "-")})


  const newObject = response.data.filter((item) => item.門市.startsWith(form_object.門市));
  const sortedData = newObject.sort((a, b) => new Date(b.TIMESTAMP_ID) - new Date(a.TIMESTAMP_ID));

  set_cashflow_list_point(sortedData)  

}
// #endregion

// #region 正常渲染模組
  return (
    <div>
    <Form name="查詢區間_金流"  initialValues={{remember: true}} onFinish={read_cashflow_point}>
      <Space direction='horizontal'>
      <Form.Item name="門市" rules={[{required: false,message: '必填欄位'}]}>
        <Select showSearch style={{width: 205}} placeholder="門市" optionFilterProp="children" filterOption={(input, option) => (option?.label ?? '').includes(input)}
        filterSort={(optionA, optionB) =>(optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())} options={member_list}/>
      </Form.Item>
        <p>起始日</p>
      <Form.Item name="起始日" rules={[{ required: true, message: '必填欄位' }]}  style={{ marginLeft: 0 }}>
        <DatePicker  format="YYYY-MM-DD" placeholder="起始日" style={{ width: 'calc(190px)' }} />
      </Form.Item>
        <p>結束日</p>
      <Form.Item name="結束日" rules={[{ required: true, message: '必填欄位' }]}  style={{ marginLeft: 0 }}>
        <DatePicker  format="YYYY-MM-DD" placeholder="結束日" style={{ width: 'calc(190px)' }} />
      </Form.Item>
        
      <Form.Item>
        <Button type="primary" shape="circle" htmlType="submit" icon={<SearchOutlined />} />
      </Form.Item>
      </Space>
    </Form>

    <Table  size="small" pagination={{ pageSize: 100 }} showExpandColumn={false} expandRowByClick={true}  columns={columns_cashflow}  dataSource={cashflow_list_point} rowKey={(record) => record.CASHFLOW_ID}/>
    
    </div>
  )
//#endregion
}