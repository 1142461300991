//#region ⭐模組需求套件--------------------------------------------------------
import 'antd/dist/reset.css';
import { WarningOutlined,DollarCircleOutlined,CodeSandboxOutlined,DropboxOutlined,IeOutlined,SearchOutlined } from '@ant-design/icons';
import { UserOutlined,AlertOutlined,LockOutlined,PlusOutlined,DollarOutlined,LoadingOutlined,BarcodeOutlined,CheckOutlined,RedoOutlined } from '@ant-design/icons';
import { Space , Button, Select, Table , Input , Divider, Card  , Tabs  , InputNumber,Typography,Descriptions,Form,message, Popconfirm,Row,Col,notification,Statistic  } from "antd";
import { useRef,useState,useEffect } from "react";
import Axios from "axios";
//#endregion

 export function 金流系統(adminvalue)
{

// #region 清單值
  const [deal_type , set_deal_type] = useState([])
  const [deal_type_ONLY消費 , set_deal_type_ONLY消費] = useState([])
  const [member_list , set_member_list] = useState([]);
    const [member_list_staff , set_member_staff_list] = useState([]);
  const [門市, set_門市] = useState(adminvalue.adminvalue[1]);

// #endregion
// #region CASHFLOW 模組
const [cashflow_list, set_cashflow_list] = useState([]);
const [cashflow_list_cashback, set_cashflow_list_cashback] = useState([]);
const [cashflow_list_online, set_cashflow_list_online] = useState([]);
const create_cashflow_cashback = async (table_object) => {
  const 回饋率 = table_object.回饋率
  if(table_object.額度減少>0)
  {  
    await Axios.post("https://pos-back-end.pktriot.net/cashflow_create", {
      會員: table_object.會員,
      門市: "SYSTEM-現金回饋",
      承辦: 門市,
      交易類型: table_object.交易類型,
      額度減少: 0,
      額度增加: table_object.額度減少*(回饋率/100),
      備註: "+"+回饋率+"%("+table_object.額度減少*(回饋率/100)+")",
    })
    await Axios.post("https://pos-back-end.pktriot.net/cashflow_create", {
      會員: table_object.會員,
      門市: 門市,
      承辦: table_object.承辦,
      交易類型: table_object.交易類型,
      額度減少: table_object.額度減少,
      額度增加: table_object.額度增加,
      備註: "+"+回饋率+"%("+table_object.額度減少*(回饋率/100)+")",
    })
  }
  else
  {

      await Axios.post("https://pos-back-end.pktriot.net/cashflow_create", {
        會員: table_object.會員,
        門市: 門市,
        承辦: table_object.承辦,
        交易類型: table_object.交易類型,
        額度減少: table_object.額度減少,
        額度增加: table_object.額度增加,
        備註: table_object.備註,
      })
  }
  await read_cashflow()
}
const update_cashflow = (table_object) => {
  
  Axios.put(`https://pos-back-end.pktriot.net/cashflow_update`, { 
    CASHFLOW_ID: table_object.CASHFLOW_ID,
    會員: table_object.會員,
    門市: table_object.門市,
    承辦: table_object.承辦,
    交易類型: table_object.交易類型,
    額度減少: table_object.額度減少,
    額度增加: table_object.額度增加,
    備註: table_object.備註,
  }).then(read_cashflow)
}
const delete_cashflow = (table_object) => {

  Axios.delete(`https://pos-back-end.pktriot.net/cashflow_delete/${table_object.CASHFLOW_ID}`).then((response) => {
  set_cashflow_list( pre => {pre.filter((cashflow) => {return cashflow.CASHFLOW_ID !== table_object.CASHFLOW_ID})})}).then(read_cashflow)
  setEditingKey('')

}


const read_cashflow = async () => {
  let response = await Axios.put("https://pos-back-end.pktriot.net/cashflow_read", {
    日期: adminvalue.adminvalue[0],
    門市: adminvalue.adminvalue[1],})

  Object.keys(response.data)
  .forEach((key) => {const timestamp = response.data[key].TIMESTAMP_ID;response.data[key].TIMESTAMP_ID = new Date(timestamp)
  .toLocaleString('zh-TW', {hour12: false})
  .replace(/\//g, "-")})

  const sortedData = response.data.sort((a, b) => new Date(b.CASHFLOW_ID) - new Date(a.CASHFLOW_ID));
  set_cashflow_list(sortedData)

  let response2 = await Axios.put("https://pos-back-end.pktriot.net/cashflow_read", {
    日期: adminvalue.adminvalue[0],  
    門市: "SYSTEM-現金回饋",})

  Object.keys(response2.data)
  .forEach((key) => {const timestamp = response2.data[key].TIMESTAMP_ID;response2.data[key].TIMESTAMP_ID = new Date(timestamp)
  .toLocaleString('zh-TW', {hour12: false})
  .replace(/\//g, "-")})
  
  const sortedData2 = response2.data.sort((a, b) => new Date(b.CASHFLOW_ID) - new Date(a.CASHFLOW_ID));
  const filterData2 = sortedData2.filter((item)=>{return item.承辦 === adminvalue.adminvalue[1]})
  set_cashflow_list_cashback(filterData2)


  let response3 = await Axios.put("https://pos-back-end.pktriot.net/cashflow_read_online", {
    門市: "SYSTEM-線上金流",})

  Object.keys(response3.data)
  .forEach((key) => {const timestamp = response3.data[key].TIMESTAMP_ID;response3.data[key].TIMESTAMP_ID = new Date(timestamp)
  .toLocaleString('zh-TW', {hour12: false})
  .replace(/\//g, "-")})
  
  const sortedData3 = response3.data.sort((a, b) => new Date(b.CASHFLOW_ID) - new Date(a.CASHFLOW_ID));
  set_cashflow_list_online(sortedData3)

  return response.data
}
const create_cashflow = (table_object) => {
  Axios.post("https://pos-back-end.pktriot.net/cashflow_create", {
    會員: table_object.會員,
    門市: 門市,
    承辦: table_object.承辦,
    交易類型: table_object.交易類型,
    額度減少: table_object.額度減少,
    額度增加: table_object.額度增加,
    備註: table_object.備註,
  }).then(read_cashflow)
}
const create_cashflow_online = (table_object) => {
  Axios.post("https://pos-back-end.pktriot.net/cashflow_create", {
    會員: table_object.會員,
    門市: "SYSTEM-線上金流",
    承辦: table_object.承辦,
    交易類型: table_object.交易類型,
    額度減少: table_object.額度減少,
    額度增加: table_object.額度增加,
    備註: table_object.備註,
  }).then(read_cashflow)
}
//#endregion

// #region MEMBER
  var 即時會員_立即判斷項
const [即時會員, set_即時會員] = useState("即時會員額度"); 
const [即時額度, set_即時額度] = useState("?");   
const cashflow_balance_read = async (e) => {
  即時會員_立即判斷項 = e
  let response = await Axios.put("https://pos-back-end.pktriot.net/cashflow_balance_read", {會員:e})
  if(即時會員_立即判斷項.startsWith("SH")){set_即時額度("❓") }
  else if(即時會員_立即判斷項.startsWith("ST")){set_即時額度("❓") }
  else if(即時會員_立即判斷項.startsWith("PO")){set_即時額度("❓") }
  else{set_即時額度(response.data.餘額) }
}

const read_member = async() => {
  let response = await Axios.put("https://pos-back-end.pktriot.net/member_read",{門市: 門市,})
  const newarray = response.data.map((item) => {return {value:item.名稱,label:item.名稱 ,}})
  const guest = newarray.filter((item) => 
  item.label.startsWith('G') || 
  item.label.startsWith('SHs') ||
  item.label.startsWith('PO')||
  item.label.startsWith('ST')
  );

  guest.sort((a, b) => { if (a.label.match(/^\d+/)) {  return parseInt(a.label) - parseInt(b.label); } else { return a.label.localeCompare(b.label); }  });



  set_member_list(guest);
  
  const staff = newarray.filter((item) => item.label.startsWith('ST'));
  staff.sort((a, b) => { if (a.label.match(/^\d+/)) {  return parseInt(a.label) - parseInt(b.label); } else { return a.label.localeCompare(b.label); }  });
  set_member_staff_list(staff)
}

// #endregion

// #region DEALTYPE
const deal_type_read = async() => {
  let response = await Axios.get("https://pos-back-end.pktriot.net/dealtype_read")

  const newarray = response.data.filter((item) => item.交易權限.startsWith('1')).map((item) => {return {value:item.交易類型,label:item.交易類型,}})

  set_deal_type(newarray)

  const newarray2 = response.data.filter((item) => item.交易類型.includes('消費')).map((item) => {return {value:item.交易類型,label:item.交易類型,}})
  set_deal_type_ONLY消費(newarray2)
  //console.log(newarray)
}
// #endregion

//#region Table Search 模組
const [searchText, setSearchText] = useState('');
const [searchedColumn, setSearchedColumn] = useState('');
const searchInput = useRef(null);
const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
};
const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
};
const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
        <div style={{padding: 8,}} onKeyDown={(e) => e.stopPropagation()} >
        <Input ref={searchInput} placeholder={`Search ${dataIndex}`} value={selectedKeys[0]} style={{ marginBottom: 8, display: 'block', }}
        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])} onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
        />
        <Space>
        <Button type="primary" onClick={() => handleSearch(selectedKeys, confirm, dataIndex)} icon={<SearchOutlined />} size="small" style={{ width: 90, }} >搜尋</Button>
        <Button onClick={() => {clearFilters && handleReset(clearFilters);confirm({ closeDropdown: false, }); setSearchText(selectedKeys[0]); setSearchedColumn(dataIndex); }} size="small" style={{ width: 90, }} >重置</Button>
        </Space>
        </div>
    ),

    filterIcon: (filtered) => (
    <SearchOutlined  style={{ color: filtered ? '#1890ff' : undefined, }} />
    ),

    onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => { if (visible) { setTimeout(() => searchInput.current?.select(), 100); } },

    render: (text) => ( text ),
});
// #endregion  


// #region FORM 模組
const [form] = Form.useForm()
const [form1] = Form.useForm()
const [form2] = Form.useForm()
const [form3] = Form.useForm()
//#endregion
// #region BUTTON LOADING  模組
  const [loadings, setLoadings] = useState([]);
    const enterLoading = (index) => {
      setLoadings((prevLoadings) => {
        const newLoadings = [...prevLoadings];
        newLoadings[index] = true;
        return newLoadings;
      });
      setTimeout(() => {
        setLoadings((prevLoadings) => {
          const newLoadings = [...prevLoadings];
          newLoadings[index] = false;
          return newLoadings;
        });
      }, 2000);
    };
// #endregion 
// #region PRELOAD 模組
const loadData = async () => {
  await read_member()
  await deal_type_read()
  await read_cashflow()
  
}
useEffect(() => {loadData()}, [])
// #endregion
// #region TABLE EDITIBLE 模組
  const [editingKey, setEditingKey] = useState('');
  const isEditing = (table_object) => table_object.CASHFLOW_ID === editingKey;
  const table_cancel = () => {setEditingKey('')}
  const table_edit = (table_object) => {
    form.setFieldsValue({
      TIMESTAMP_ID: '',
      會員: '',
      門市: '',
      承辦: '',
      交易類型: '',
      額度減少: '',
      額度增加: '',
      備註: '',
      ...table_object,
    });setEditingKey(table_object.CASHFLOW_ID);
  };
  const table_save = async (table_object) => {
      const row = await form.validateFields();
      const newData = [...cashflow_list];
      const index = newData.findIndex((item) => table_object.CASHFLOW_ID === item.CASHFLOW_ID);
    try {
      
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        set_cashflow_list(newData);
      } else {
        console.log("無資料")
      }
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
    finally {
      setEditingKey('')
      console.log(newData[index])
      update_cashflow(newData[index])

    }
  }
  const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    inputNode,
    required, // 新增 required 屬性
    ...restProps
  }) => {
    

    if (dataIndex === '備註') {
      inputNode = <Input style={{ width:150 }} size="small"   type="text"  />
    } else if (dataIndex === '額度減少'||dataIndex === '額度增加') {
      inputNode = <InputNumber disabled style={{width: 100}} size="small" />
    } else if (dataIndex === '會員' ) {
      inputNode = <Select disabled showSearch style={{width: 100}}  size="small" optionFilterProp="children" filterOption={(input, option) => (option?.label ?? '').includes(input)}
      filterSort={(optionA, optionB) =>(optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())} options={member_list} />
    } else if (dataIndex === '門市' ) {
      inputNode = <Select disabled showSearch style={{width: 100}}  size="small" optionFilterProp="children" filterOption={(input, option) => (option?.label ?? '').includes(input)}
      filterSort={(optionA, optionB) =>(optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())}  />
    } else if (dataIndex === '承辦' ) {
      inputNode = <Select showSearch style={{width: 100}}  size="small" optionFilterProp="children" filterOption={(input, option) => (option?.label ?? '').includes(input)}
      filterSort={(optionA, optionB) =>(optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())} options={member_list_staff} />
    } else if (dataIndex === '交易類型') {
      inputNode = <Select showSearch style={{width: 150}} size="small" optionFilterProp="children" filterOption={(input, option) => (option?.label ?? '').includes(input)}
      filterSort={(optionA, optionB) =>(optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())} options={deal_type} />
    } 

    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item name={dataIndex}  style={{margin: 0,width:80}}  rules={[ {required: required === true,message: `變更請備註`}]}> 
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };
// #endregion
// #region TABLE 模組
const table_columns = [
  {
    Key:'0',
    width: 180,
    fixed: 'left',
    title:'交易時間',
    dataIndex:'TIMESTAMP_ID',
    ...getColumnSearchProps('TIMESTAMP_ID'),
  },
  {
    Key:'1',
    width: 160,
    fixed: 'left',
    editable: true,
    required: false,
    title:'會員',
    dataIndex:'會員',
    ...getColumnSearchProps('會員'),
  },

  {
    Key:'2',width: 160,editable: true,required: false,
    title:'門市',
    dataIndex:'門市'
  },
  {
    Key:'3',
    title:'承辦',width: 160,editable: true,required: false,
    dataIndex:'承辦'
  },
  {
    Key:'4',
    title:'交易類型',width: 210,editable: true,required: false,
    dataIndex:'交易類型'
  },
  {
    Key:'5',
    title:'額度減少(➖)',width: 160,editable: true,required: false,
    dataIndex:'額度減少',

    render: (text, record) => {return <strong style={{ color: '#4285f4' , fontSize: '20px' }}>{text}</strong>}
  },
  {
    Key:'6',
    title:'額度增加(➕)',width: 160,editable: true,required: false,
    dataIndex:'額度增加',

    render: (text, record) => {return <strong style={{ color: '#EB6472' , fontSize: '20px' }}>{text}</strong>}
  },
  {
    Key:'7',
    title:'備註',width: 200,editable: true,
    dataIndex:'備註',
    render: (text, record) => {return < span style={{ color: '#0F3B53' , fontSize: '10px' }}>{text}</span>}
  },
  {
    Key:'8',
    title:'餘額',width: 135,editable: true,fixed: 'right',
    dataIndex:'餘額',
    render: (text, record) => {return <strong style={{ color: '#FFC14D' , fontSize: '20px' }}>{text}</strong>}
  },
  {
    Key:'9',
    title:'操作',width: 75,fixed: 'right',

    render: (_,table_object) => {

      const editable = isEditing(table_object);
      return editable ? 
      ( <span>
          <Typography.Link onClick={() => table_save(table_object)} style={{marginRight: 8}}> ✅更新 </Typography.Link>
          <Typography.Link onClick={table_cancel} style={{marginLeft: 20}}> 🔵取消變更 </Typography.Link>
        </span>
      ) 
      : 
      (
        <Typography.Link disabled={editingKey !== ''} onClick={() => table_edit(table_object)}> 🔒編輯 </Typography.Link>
      )
    }
  }  

]
const mergedColumns = table_columns.map((col) => {
  if (!col.editable) {
    return col;
  }
  return {
    ...col,
    onCell: (record) => ({
      record,
      dataIndex: col.dataIndex,
      title: col.title,
      editing: isEditing(record),
    }),
  };
})


// #endregion

// #region 正常渲染模組
  return (
<div style={{ padding: '15px' }}>
      <Row gutter={16}>
        <Col span={3}>
          <Statistic title={即時會員} value={即時額度} prefix={<DollarOutlined />} style={{ width: 200 }} />
        </Col>
        <Col span={21}>
          <Card title="✅新增金流" style={{ width: 550 }}> 
            <Form
              name="cashflow_creation"
              initialValues={{ remember: true }}
              form={form1}
              onFinish={(formdata) => {
                if (loadings[3]) {
                  console.log("❗禁止裝置:阻止重複輸入")
                } else {
                  enterLoading(3);
                  create_cashflow(formdata);
                  form1.resetFields(['額度減少', '額度增加'])
                }
              }}
            >
              <Space direction="vertical" size="middle">
                <Space>
                  <Form.Item name="會員" rules={[{ required: true, message: '必填欄位' }]}>
                    <Select
                      showSearch
                      style={{ width: 200 }}
                      suffixIcon={<UserOutlined />}
                      placeholder="會員"
                      optionFilterProp="children"
                      filterOption={(input, option) => (option?.label ?? "").includes(input)}
                      filterSort={(optionA, optionB) => (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())}
                      options={member_list}
                      onChange={(e) => {
                        set_即時會員(e)
                        cashflow_balance_read(e)
                      }}
                    />
                  </Form.Item>
                  <Form.Item name="門市" initialValues={門市} rules={[{ required: false, message: '必填欄位' }]}>
                    <Input style={{ width: 150 }} suffixIcon={<AlertOutlined />} defaultValue={門市} disabled />
                  </Form.Item>
                  <Form.Item name="承辦" rules={[{ required: true, message: '必填欄位' }]}>
                    <Select
                      showSearch
                      style={{ width: 150 }}
                      suffixIcon={<AlertOutlined />}
                      placeholder="承辦"
                      optionFilterProp="children"
                      filterOption={(input, option) => (option?.label ?? "").includes(input)}
                      filterSort={(optionA, optionB) => (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())}
                      options={member_list_staff}
                    />
                  </Form.Item>
                </Space>
                <Space>
                  <Form.Item name="交易類型" rules={[{ required: true, message: '必填欄位' }]}>
                    <Select
                      showSearch
                      style={{ width: 200 }}
                      placeholder="交易類型"
                      optionFilterProp="children"
                      filterOption={(input, option) => (option?.label ?? '').includes(input)}
                      filterSort={(optionA, optionB) => (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())}
                      options={deal_type}
                    />
                  </Form.Item>
                  <Form.Item name="額度減少" rules={[{ required: false, message: '必填欄位' }]}>
                    <Input style={{ width: 'calc(150px)' }} placeholder="額度減少" type="number" />
                  </Form.Item>
                  <Form.Item name="額度增加" rules={[{ required: false, message: '必填欄位' }]}>
                    <Input style={{ width: 'calc(150px)' }} placeholder="額度增加" type="number" />
                  </Form.Item>
                </Space>
                <Space>
                  <Form.Item name="備註" rules={[{ required: false, message: '必填欄位' }]}>
                    <Input style={{ width: 'calc(400px)' }} placeholder="備註" type="text" />
                  </Form.Item>
                  <Form.Item>
                    <Button type="primary" htmlType="submit" icon={<CheckOutlined />} loading={loadings[1]} onClick={() => enterLoading(1)}>一般金流</Button>
                  </Form.Item>
                </Space>
              </Space>
            </Form>
          </Card>
        </Col>
      </Row>

      <Divider />

      <Form form={form} component={false}>
        <Table
          size="small"
          bordered
          components={{ body: { cell: EditableCell } }}
          dataSource={cashflow_list}
          columns={mergedColumns}
          rowClassName="editable-row"
          pagination={{ onChange: table_cancel }}
          scroll={{ x: 'max-content' }}
        />
      </Form>
    </div>
  )
//#endregion
}