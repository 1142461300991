// #region import Zone
import 'antd/dist/reset.css';
import Paper from '@mui/material/Paper';
import { SearchOutlined } from '@ant-design/icons';
import { UserOutlined,RedoOutlined } from '@ant-design/icons';
import { Space , Button, Select, Table , Input , Divider, DatePicker ,  InputNumber,Typography,Form,Popover,Row,Col } from "antd";
import { useState,useEffect } from "react";
import Axios from "axios";

// #endregion


export function 時數系統_夥伴(adminvalue){ 

//#region PUNCHCARD 模組(RC)
const [punch_list , set_punch_list] = useState([]);
const read_punch = async(table_object) => {
  let response = await Axios.post("https://pos-back-end.pktriot.net/NBACM_0101",{
    起始日: table_object.起始日,
    結束日: table_object.結束日,
    門市: table_object.門市,
    夥伴: adminvalue.adminvalue[1],
  })

  Object.keys(response.data)
  .forEach((key) => {const timestamp = response.data[key].打卡時間;response.data[key].打卡時間 = new Date(timestamp)
  .toLocaleString('zh-TW', {hour12: false})
  .replace(/\//g, "-")})

  const sortedData = response.data.sort((a, b) => new Date(b.打卡時間) - new Date(a.打卡時間));
  set_punch_list(sortedData)

}


//#endregion
const [form_參數項] = Form.useForm()

//#region Table 模組
  const columns_打卡系統 = [
      {
        Key:'1',
        title:'時間',
        dataIndex:'打卡時間', 
      },
      {
        Key:'2',
        title:'類型',
        dataIndex:'打卡類型', 
      },
      {
        Key:'3',
        title:'夥伴',
        dataIndex:'夥伴',
      },
      {
        Key:'4',
        title:'正常',
        dataIndex:'正常時數',
      },
      {
        Key:'5',
        title:'特殊',
        dataIndex:'特殊時數',
      },
      {
        Key:'6',
        title:'備註',
        dataIndex:'備註',
      },
      
  ]
//#endregion



//#region 計算即時
const [總時數, set_總時數] = useState(0)
const [正常時數, set_正常時數] = useState(0)
const [特殊時數, set_特殊時數] = useState(0)

const calculation = async () => {
  const sum_正常時數 = punch_list.reduce((accumulator, currentValue) => {return accumulator + parseFloat(currentValue.正常時數)}, 0); 
  set_正常時數(sum_正常時數) 
  const sum_特殊時數 = punch_list.reduce((accumulator, currentValue) => {return accumulator + parseFloat(currentValue.特殊時數)}, 0); 
  set_特殊時數(sum_特殊時數) 
  const sum_總時數 = punch_list.reduce((accumulator, currentValue) => {return accumulator + parseFloat(currentValue.正常時數) + parseFloat(currentValue.特殊時數)}, 0);
  set_總時數(sum_總時數) 
}

useEffect(() => {calculation()}, [punch_list])
// #endregion

//#region 結帳狀態模組

return (<div className="App">
<Space direction="horizontal"  align="start">
  <Form name="參數項"  form={form_參數項} onFinish={(data)=>{read_punch(data)}}>
    <Space direction="horizontal" >

      <Paper elevation={3}   >

        <Space direction="vertical" style={{ margin: 15 }}>
          <p style={{ color: '#EB6472' , fontSize: '20px' ,fontWeight: 'bold',textAlign: 'center'}}>參數項<Divider /></p>
          <Row>
          <Col flex={1}>
            <Form.Item name="起始日"  rules={[{ required: true, message: '必填欄位' }]}  >
              <DatePicker placeholder={"起始日"} style={{width: 175}} format="YYYY-MM-DD"  />
            </Form.Item>
            <Form.Item name="結束日"  rules={[{ required: true, message: '必填欄位' }]}  >
              <DatePicker placeholder={"結束日"} style={{width: 175}} format="YYYY-MM-DD"  />
            </Form.Item>
            <Form.Item name="查詢" >
              <Button   htmlType="submit" icon={<SearchOutlined /> }>查詢</Button>
            </Form.Item>
          </Col>
          </Row>

        </Space>




      </Paper>

    </Space>  
  </Form>

  <Paper elevation={3}  >

    <Space direction="vertical" style={{ margin: 15 }}>
      <p style={{ color: '#EB6472' , fontSize: '20px' ,fontWeight: 'bold',textAlign: 'center'}}>資料項<Divider /></p>
      <Space direction="vertical" >
        <span>總時數：<InputNumber disabled value={總時數} style={{ width: 85 }} /></span>
        <span>表　定：<InputNumber disabled value={正常時數} style={{ width: 85 }} /></span>
        <span>額　外：<InputNumber disabled value={特殊時數} style={{ width: 85 }} /></span>

      </Space>

    </Space>




  </Paper>

</Space>

<Divider orientation="left">時數表</Divider>
  <Paper >
    <Table size="small" pagination={{ pageSize: 100 }} columns={columns_打卡系統}  dataSource={punch_list} rowKey={(record) => record.ID}/>
  </Paper>


</div>)
// #endregion



}