// #region import Zone
import 'antd/dist/reset.css';
import Paper from '@mui/material/Paper';
import { SearchOutlined } from '@ant-design/icons';
import { UserOutlined,RedoOutlined } from '@ant-design/icons';
import { Space , Button, Select, Table , Input , Divider, DatePicker ,  InputNumber,Typography,Form,Popover,Row,Col } from "antd";
import { useState,useEffect } from "react";
import Axios from "axios";

const { TextArea } = Input;

// #endregion


export function 考勤系統(){ 
//#region 時間模組
const [起始日 , set_起始日] = useState("");
const [結束日 , set_結束日] = useState("");
//#endregion


//#region 打卡模組 讀取 更新 刪除
const [考勤列表 , set_考勤列表] = useState([]);
const [出勤紀錄 , set_出勤紀錄] = useState([]);
const API_讀取_區間內考勤列表 = async(table_object) => {

  let response = await Axios.post("https://pos-back-end.pktriot.net/R9Bwgp2k",{ 
    起始日: table_object.起始日,
    結束日: table_object.結束日,
  })
  const sortedData = response.data.sort((a, b) => {
    // 將考勤對象從 "ST-001" 這樣的形式轉換回 1 的 INT 值
    const memberIdA = parseInt(a.考勤對象.split('-')[1]);
    const memberIdB = parseInt(b.考勤對象.split('-')[1]);
  
    // 根據考勤對象排序
    if (memberIdA < memberIdB) {
      return -1;
    } else if (memberIdA > memberIdB) {
      return 1;
    } else {
      return 0;
    }
  });
  set_考勤列表(sortedData)

}
const API_讀取_區間內出勤列表 = async(考勤對象) => {
  let response = await Axios.post("https://pos-back-end.pktriot.net/3GStQVpf",{
    起始日: 起始日,
    結束日: 結束日,
    考勤對象: 考勤對象,
  })

  Object.keys(response.data)
  .forEach((key) => {const timestamp = response.data[key].打卡時間;response.data[key].打卡時間 = new Date(timestamp)
  .toLocaleString('zh-TW', {hour12: false})
  .replace(/\//g, "-")})

  const sortedData = response.data.sort((a, b) => new Date(b.打卡時間) - new Date(a.打卡時間));
  set_出勤紀錄(sortedData)

}
const API_更新_核准單項考勤 = async(table_object) => {
  await Axios.post("https://pos-back-end.pktriot.net/WwsrWuCP",{
    ID:table_object.ID,
  })
  const new_item = {
    ID:table_object.ID,
    門市加密:table_object.門市加密,
    打卡時間:table_object.打卡時間,
    打卡類型:table_object.打卡類型,
    正常時數:table_object.正常時數,
    考勤:"✔️",
    備註:table_object.備註,
  }

  set_出勤紀錄(出勤紀錄.map(item => 
    item.ID === table_object.ID
      ? new_item
      : item
  ));


}
const update_punch = async(table_object) => {
  await Axios.post("https://pos-back-end.pktriot.net/NBACM_0103",{
    ID:table_object.ID,
    打卡類型:table_object.打卡類型,
    正常時數:table_object.正常時數,
    考勤: "更新",
    備註: table_object.備註,
  })
  const new_item = {
    ID:table_object.ID,
    門市加密:table_object.門市加密,
    打卡時間:table_object.打卡時間,
    打卡類型:table_object.打卡類型,
    正常時數:table_object.正常時數,
    考勤: "更新",
    備註:table_object.備註,
  }

  set_出勤紀錄(出勤紀錄.map(item => 
    item.ID === table_object.ID
      ? new_item
      : item
  ));


}
const delete_punch = async(table_object) => {
  await Axios.post("https://pos-back-end.pktriot.net/NBACM_0104",{ ID:table_object.ID, })

  set_出勤紀錄(出勤紀錄.filter(item => item.ID !== table_object.ID ));


}


//#endregion

//#region 會員模組 讀取
const [member_list_point , set_member_point_list] = useState([]);
const [member_list_staff , set_member_staff_list] = useState([]);
const read_member = async() => {
  let response = await Axios.put("https://pos-back-end.pktriot.net/member_get_manager")

  const newarray = response.data.map((item) => {return {value:item.名稱,label:item.名稱 ,}})

  const staff = newarray.filter((item) => item.label.startsWith('ST'));
  set_member_staff_list(staff);

  const point = newarray.filter((item) => item.label.startsWith('PO'));
  set_member_point_list(point)
}
// #endregion
//#region 選列模組
const 選列_打卡列表 = {
  onChange: async (selectedRowKeys, selectedRowsdata) => {
    await API_讀取_區間內出勤列表(selectedRowsdata[0].考勤對象)
    

    }
  ,
};
//#endregion
//#region 欄位模組
  const columns_出勤紀錄 = [
    {
        Key:'0',
        title:'門市',
        dataIndex:'門市加密',
      },
      {
        Key:'1',
        title:'時間',
        dataIndex:'打卡時間', 
      },
      {
        Key:'2',
        title:'類型',
        dataIndex:'打卡類型', 
      },
      {
        Key:'4',
        title:'正常',
        dataIndex:'正常時數',
      },
      {
        Key:'5',
        title:'考勤',
        dataIndex:'考勤',
      },
      {
        Key:'6',
        title:'備註',
        dataIndex:'備註',
      },
      {
        Key:'7',
        title:'操作',
    
        render: (_,table_object) => {
          return( 
          <span>
            <Typography.Link onClick={() =>{API_更新_核准單項考勤(table_object)} } style={{marginRight: 8}}> ✅考勤 </Typography.Link>
            <Popover title={table_object.打卡時間} content={
              <Form form={form_打卡清單} name="編輯打卡" onFinish={(data)=>{update_punch(data)}} > 
              <Space direction="vertical"  align="start">
                <Space direction="horizontal"  align="start">

                  <Form.Item name="ID"  /> 
                  <Form.Item name="打卡時間"  /> 
                  <Form.Item name="門市加密"  /> 

                  <Form.Item name="打卡類型" rules={[{ required: true, message: '必填欄位' }]} >
                    <Select style={{width: 100}} suffixIcon={<UserOutlined/>}  options={list_打卡類型} />  
                  </Form.Item>
                  <Form.Item name="正常時數" rules={[{ required: false, message: '必填欄位' }]} >
                    <Input style={{ width: 100 }}   type="number" />
                  </Form.Item>
                </Space>

                <Space direction="horizontal"  align="start">
                  <div > 備註：</div> 
                  <Form.Item name="備註" rules={[{ required: false, message: '必填欄位' }]} >
                    <TextArea rows={2}  style={{ width: 120}} />
                  </Form.Item>
                

                <Form.Item><Button type="primary" htmlType="submit">確認</Button></Form.Item>
                </Space>
              </Space>
              </Form>

            } trigger="click"  >
              <Typography.Link onClick={() =>{

                form_打卡清單.setFieldsValue({
                  ID:table_object.ID,
                  門市加密:table_object.門市加密,
                  打卡時間:table_object.打卡時間,
                  打卡類型:table_object.打卡類型,
                  正常時數:table_object.正常時數,
                  考勤: table_object.考勤,
                  備註: table_object.備註,

                })

              } } style={{marginRight: 8}}> ⚠️更新 </Typography.Link>
            </Popover>
              <Typography.Link onClick={() =>{delete_punch(table_object)} } style={{marginRight: 8}}> ❌刪除 </Typography.Link>
          </span>
          )
        }
      } 
      
  ]
  const columns_考勤列表 = [
    {
        Key:'0',
        title:'考勤對象',
        dataIndex:'考勤對象',
      },
      {
        Key:'1',
        title:'未考勤時數',
        dataIndex:'未考勤時數', 
      },
      {
        Key:'2',
        title:'已考勤時數',
        dataIndex:'已考勤時數', 
      },
      {
        Key:'3',
        title:'考勤規則',
        dataIndex:'考勤規則', 
      },
      
      
  ]
  const list_打卡類型 =[{value:"🟢上班",label:"🟢上班"},{value:"🔴下班",label:"🔴下班"}]
//#endregion
//#region 預設讀取
const loadData = async () => {
}
useEffect(() => {loadData()}, [])



// #endregion

//#region 表單模組
const [form_參數項] = Form.useForm()
const [form_打卡清單] = Form.useForm()
//#endregion

//#region 前端渲染

return (<div className="App">
<Space direction="horizontal"  align="start">
  <Form name="參數項"  form={form_參數項} onFinish={(data)=>{API_讀取_區間內考勤列表(data)}}>
    <Space direction="horizontal" >

      <Paper elevation={3}   >

        <Space direction="vertical" style={{ margin: 15 }}>
          <p style={{ color: '#EB6472' , fontSize: '20px' ,fontWeight: 'bold',textAlign: 'center'}}>參數項<Divider /></p>
          
          <Row>
          <Col flex={1}>
            <Form.Item name="起始日"  rules={[{ required: true, message: '必填欄位' }]}  >
              <DatePicker placeholder={"起始日"} style={{width: 175}} format="YYYY-MM-DD" onChange={(key)=>{set_起始日(key)}} />
            </Form.Item>
            <Form.Item name="結束日"  rules={[{ required: true, message: '必填欄位' }]}  >
              <DatePicker placeholder={"結束日"} style={{width: 175}} format="YYYY-MM-DD" onChange={(key)=>{set_結束日(key)}} />
            </Form.Item>
            <Space direction="horizontal" >

            <Form.Item name="查詢" >
              <Button   htmlType="submit" icon={<SearchOutlined /> }>查詢</Button>
            </Form.Item>

            </Space>

          </Col>
          </Row>

        </Space>




      </Paper>

    </Space>  
  </Form>

  <Paper elevation={3}  style={{ width: 400 }} >
    <Table size="small" pagination={{ pageSize: 6 }} columns={columns_考勤列表}  dataSource={考勤列表} rowKey={(record) => record.考勤對象} rowSelection={{ type: "radio", ...選列_打卡列表, }} />
  </Paper>

</Space>

<Divider orientation="left">出勤紀錄</Divider>
  <Paper elevation={3}  style={{ width: 1000 }} >
    <Table size="small" pagination={{ pageSize: 100 }} columns={columns_出勤紀錄}  dataSource={出勤紀錄} rowKey={(record) => record.ID}  />
  </Paper>


</div>)
// #endregion



}