// #region import Zone
import 'antd/dist/reset.css';
import { DollarCircleOutlined,DropboxOutlined,BarcodeOutlined } from '@ant-design/icons';
import { Tabs } from "antd";


// #endregion


import 結帳模組 from '../sub/結帳模組';
import 金流模組 from '../sub/金流模組';
import 物流模組 from '../sub/物流模組';


export function 本日帳務(adminvalue) { 
  
  const TAB = [
    {key: '0',label:  (<span><BarcodeOutlined />結帳</span>),
      children: <span><結帳模組 adminvalue={[adminvalue.adminvalue[0],adminvalue.adminvalue[1]]} /></span>
      ,
    }
    ,
    {key: '1',label: (<span><DollarCircleOutlined />金流</span>),
      children: <span><金流模組 adminvalue={[adminvalue.adminvalue[0],adminvalue.adminvalue[1]]} /></span>
  
      ,
    }
    ,
    {key: '2',label: (<span><DropboxOutlined />物流</span>),
    children: <span><物流模組 adminvalue={[adminvalue.adminvalue[0],adminvalue.adminvalue[1]]} /></span>
  
      ,
    }
  ]

  return ( 
    <Tabs  size="large" type="card" style={{ marginLeft: "20px" }} defaultActiveKey="0" items={TAB}  />
//#endregion
)}

