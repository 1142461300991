// #region import Zone

import 'antd/dist/reset.css';
import { UserOutlined,SearchOutlined} from '@ant-design/icons';
import { Space , Button, Select, Table , Input , DatePicker,Form,Divider, Card  } from "antd";
import { useRef,useState,useEffect } from "react";
import Axios from "axios";
import React from 'react';

// #endregion




export function 額度系統(adminvalue)
{

//#region Cashflow 模組
const [cashflow_list, set_cashflow_list] = useState([]);

const read_cashflow = async (form_object) => {
  let response = await Axios.put("https://pos-back-end.pktriot.net/cashflow_read_member",{
    會員: adminvalue.adminvalue[1],
    起始日: form_object.起始日,
    結束日: form_object.結束日,
  })

  Object.keys(response.data)
  .forEach((key) => {const timestamp = response.data[key].TIMESTAMP_ID;response.data[key].TIMESTAMP_ID = new Date(timestamp)
  .toLocaleString('zh-TW', {hour12: false})
  .replace(/\//g, "-")})

  set_cashflow_list(response.data)  
}
//#endregion

//#region Table 模組
const columns_cashflow = [
  {
      Key:'0',
      title:'時間',
      dataIndex:'TIMESTAMP_ID',
      fixed: 'left',
    },
    {
      Key:'1',
      title:'摘要',
      dataIndex:'交易類型',
      ellipsis: true,
    },
    {
      Key:'2',
      title:'➖',
      dataIndex:'額度減少',
      render: (text, record) => {return <strong style={{ color: '#4285f4'  }}>{text}</strong>}
    },
    {
      Key:'3',
      title:'➕',
      dataIndex:'額度增加',
      render: (text, record) => {return <strong style={{ color: '#EB6472'  }}>{text}</strong>}
    },
    {
      Key:'4',
      title:'餘額',
      dataIndex:'餘額',
      fixed: 'right',
      render: (text, record) => {return <strong style={{ color: '#FFC14D'  }}>{text}</strong>}
    },
];
//#endregion



  return (
    <div><Card>
      <Form name="查詢區間_金流"  initialValues={{remember: true}} onFinish={read_cashflow}>
        <Space direction='horizontal' style={{ marginTop:15,marginLeft:15 }}>
        <Form.Item name="BUTTON" rules={[{ required: false, message: '必填欄位' }]}  >
          <Button type="primary" shape="circle" htmlType="submit" icon={<SearchOutlined />} />
        </Form.Item>
        <Form.Item name="起始日" rules={[{ required: true, message: '必填欄位' }]}  style={{ marginLeft: 0 }}>
          <DatePicker  format="YYYY-MM-DD" placeholder="起始日"  style={{width: 125}} />
        </Form.Item>
        <Form.Item name="結束日" rules={[{ required: true, message: '必填欄位' }]}  style={{ marginLeft: 0 }}>
          <DatePicker  format="YYYY-MM-DD" placeholder="結束日"  style={{width: 125}} />
        </Form.Item>
          
        </Space>
      </Form>
      <Table  size="small" pagination={{ pageSize: 100 }}  columns={columns_cashflow}  dataSource={cashflow_list} rowKey={(record) => record.CASHFLOW_ID}
          expandable={{
            expandedRowRender: (record) => <p>{record.備註}</p>,
            rowExpandable: (record) => record.name !== 'Not Expandable',
          }}
      />
    </Card></div>
  
  
  
  
  )
}