// #region import Zone
import 'antd/dist/reset.css';
import Paper from '@mui/material/Paper';
import Axios from "axios";
import moment from 'moment';
import { CodeSandboxOutlined ,WarningOutlined ,SearchOutlined,EyeOutlined,DropboxOutlined,DollarCircleOutlined,UserOutlined,AlertOutlined,PlusOutlined  } from '@ant-design/icons';
import { Space , Button, Select, Table , Input , Form, DatePicker , Tabs  , Col,Row ,InputNumber,Radio, Typography, Divider  } from "antd";
import { useRef,useState,useEffect } from "react";
const { TextArea } = Input;



// #endregion



export function 查詢報表(adminvalue){

//#region 查詢報表
  const [summary_list , set_summary_list] = useState([]);
  const API_門市系統_查詢報表 = async(form_object) => {
    let response = await Axios.post("https://pos-back-end.pktriot.net/point_read_dayreport",{
      門市: 門市,
      起始日: form_object.起始日,
      結束日: form_object.結束日,
    })
    set_summary_list(response.data)
  }
//#endregion
    const [日報日期, set_日報日期] = useState("")
    const [日報門市, set_日報門市] = useState("")
    const [日報承辦, set_日報承辦] = useState("");
    const [日報備註, set_日報備註] = useState("");
    const [日報帳差, set_日報帳差] = useState("");

    const [台彩銷售, set_台彩銷售] = useState(0);
    const [台彩兌獎, set_台彩兌獎] = useState(0);
      const [台彩兌獎_電腦型, set_台彩兌獎_電腦型] = useState(0);
      const [台彩兌獎_立即型, set_台彩兌獎_立即型] = useState(0);
    const [運彩銷售, set_運彩銷售] = useState(0);
    const [運彩兌獎, set_運彩兌獎] = useState(0);
      const [運彩兌獎_有效, set_運彩兌獎_有效] = useState(0);
      const [運彩兌獎_無效, set_運彩兌獎_無效] = useState(0);
    const [金流增加, set_金流增加] = useState(0);
    const [金流減少, set_金流減少] = useState(0);
    const [物流增加, set_物流增加] = useState(0);
    const [物流減少, set_物流減少] = useState(0);

    const [應有前期, set_應有前期] = useState(0);
      const [前期台幣現值, set_前期台幣現值] = useState(0);
      const [前期等價現值, set_前期等價現值] = useState(0);
      const [前期刮樂現值, set_前期刮樂現值] = useState(0);

    const [額度增加, set_額度增加] = useState(0);
    const [額度減少, set_額度減少] = useState(0);
    const [應有後期, set_應有後期] = useState(0);
    const [盤點後期, set_盤點後期] = useState(0);
    
    const [台幣現值, set_台幣現值] = useState(0);
    const [等價現值, set_等價現值] = useState(0);
    const [刮樂現值, set_刮樂現值] = useState(0);

    const [現金0001, set_現金0001] = useState(0);
    const [現金0005, set_現金0005] = useState(0);
    const [現金0010, set_現金0010] = useState(0);
    const [現金0050, set_現金0050] = useState(0);
    const [現金0100, set_現金0100] = useState(0);
    const [現金0200, set_現金0200] = useState(0);
    const [現金0500, set_現金0500] = useState(0);
    const [現金1000, set_現金1000] = useState(0);
    const [現金2000, set_現金2000] = useState(0);


//#region 初始日期與門市模組
const [門市, set_門市] = useState(adminvalue.adminvalue[1])
const [查詢期號, set_查詢期號] = useState('0')
const [查詢本號, set_查詢本號] = useState('0')
const [批購日期, set_批購日期] = useState('0')
const [經銷證號, set_經銷證號] = useState(0)
const [掛籍結果, set_掛籍結果] = useState(0)
//#endregion
//#region BUTTON LOADING  模組
const [loadings, setLoadings] = useState([]);
const enterLoading = (index,按鈕冷卻毫秒數) => {
  setLoadings((prevLoadings) => {
    const newLoadings = [...prevLoadings];
    newLoadings[index] = true;
    return newLoadings;
  });
  setTimeout(() => {
    setLoadings((prevLoadings) => {
      const newLoadings = [...prevLoadings];
      newLoadings[index] = false;
      return newLoadings;
    });
  }, 按鈕冷卻毫秒數);
};
// #endregion 

//#region ITEM 模組
const [item_list , set_item_list] = useState([]);
const [item_origin , set_item_origin] = useState([]);
const read_item = async() => {
  let response = await Axios.get("https://pos-back-end.pktriot.net/item_read") 
  set_item_origin(response.data)
  const newarray = response.data.map((item) => {return {value:item.items_name,label:item.items_name ,}})
  set_item_list(newarray)
}
// #endregion
//#region MEMBER 模組
const [member_list , set_member_list] = useState([]);
const [member_list_staff , set_member_staff_list] = useState([]);
const read_member = async() => {
  let response = await Axios.put("https://pos-back-end.pktriot.net/member_read",{門市: 門市,})
  const newarray = response.data.map((item) => {return {value:item.名稱,label:item.名稱 ,}})
  set_member_list(newarray)
  const staff = newarray.filter((item) => item.label.startsWith('ST'));
  set_member_staff_list(staff)

}
//#endregion
//#region CASHFLOW 模組
const [cashflow_list_summary, set_cashflow_list_summary] = useState([]);
const [cashflow_list_point, set_cashflow_list_point] = useState([]);

const read_cashflow_summary = async (form_object) => {
  let response = await Axios.put("https://pos-back-end.pktriot.net/cashflow_read_point",{
    門市: 門市,
    起始日: form_object.起始日,
    結束日: form_object.結束日,
  })

  Object.keys(response.data)
  .forEach((key) => {const timestamp = response.data[key].TIMESTAMP_ID;response.data[key].TIMESTAMP_ID = new Date(timestamp)
  .toLocaleString('zh-TW', {hour12: false})
  .replace(/\//g, "-")})



  set_cashflow_list_summary(response.data)  

}


const read_cashflow_point = async (form_object) => {
  let response = await Axios.put("https://pos-back-end.pktriot.net/cashflow_read_point",{
    門市: 門市,
    起始日: form_object.起始日,
    結束日: form_object.結束日,
  })

  Object.keys(response.data)
  .forEach((key) => {const timestamp = response.data[key].TIMESTAMP_ID;response.data[key].TIMESTAMP_ID = new Date(timestamp)
  .toLocaleString('zh-TW', {hour12: false})
  .replace(/\//g, "-")})


  const newObject = response.data.filter((item) => item.門市.startsWith(adminvalue.adminvalue[1]));
  const sortedData = newObject.sort((a, b) => new Date(b.TIMESTAMP_ID) - new Date(a.TIMESTAMP_ID));

  set_cashflow_list_point(sortedData)  

}
//#endregion
//#region LOGISTIC 模組


const [logistic_list_point, set_logistic_list_point] = useState([]);
const read_logistic_point = (form_object) => {
  Axios.put("https://pos-back-end.pktriot.net/logistic_read_point", { 
    門市: 門市,
    起始日: form_object.起始日,
    結束日: form_object.結束日,
  }).then((response) => {

  Object.keys(response.data).forEach((key) => {const timestamp = response.data[key].TIMESTAMP_ID;response.data[key].TIMESTAMP_ID = new Date(timestamp).toLocaleString('zh-TW', {hour12: false});});
  set_logistic_list_point(response.data)})
}

const read_logistic_purchase = async (期號,本號) => {
  set_查詢期號(期號)
  set_查詢本號(本號)
  set_批購日期("?")
  set_經銷證號("?")
  set_掛籍結果("?")
  let response1 = await Axios.put("https://pos-back-end.pktriot.net/logistic_read_purchase",{期號: 期號,本號: 本號})
  set_批購日期(response1.data[0].日期)
  set_經銷證號(response1.data[0].證號)
  let response2 = await Axios.put("https://pos-back-end.pktriot.net/logistic_read_purchase_check_dealer",{證號: response1.data[0].證號})
  set_掛籍結果(response2.data[0].掛籍)
}


  //#endregion 
//#region INENTORY & UNAUTHOR 模組
const [unauthorized_list , set_unauthorized_list] = useState([]);
const [dayinventory_today_list , set_dayinventory_today_list] = useState([]);


const read_dayinventory = async(查詢日期) => {
  await Axios.get("https://pos-back-end.pktriot.net/dayinventory_read" + '?日期=' + 查詢日期 + '&門市=' + 門市).then((response) => {
    Object.keys(response.data).forEach((key) => {const timestamp = response.data[key].日期;response.data[key].日期 = new Date(timestamp).toLocaleString('zh-TW', {year: 'numeric',month: 'numeric',day: 'numeric'})})
    set_dayinventory_today_list(response.data)})
}




const read_unauthorized = async(查詢日期) => {
  Axios.get("https://pos-back-end.pktriot.net/unauthorized_read" + '?日期=' + 查詢日期 + '&門市=' + 門市).then((response) => {
  Object.keys(response.data).forEach((key) => {const timestamp = response.data[key].銷售日期;response.data[key].銷售日期 = new Date(timestamp).toLocaleString('zh-TW', {year: 'numeric',month: 'numeric',day: 'numeric'})})
  Object.keys(response.data).forEach((key) => {const timestamp = response.data[key].入帳日期;response.data[key].入帳日期 = new Date(timestamp).toLocaleString('zh-TW', {year: 'numeric',month: 'numeric',day: 'numeric'})})
  Object.keys(response.data).forEach((key) => {const timestamp = response.data[key].出帳日期;response.data[key].出帳日期 = new Date(timestamp).toLocaleString('zh-TW', {year: 'numeric',month: 'numeric',day: 'numeric'})})
  Object.keys(response.data).forEach((key) => {if(response.data[key].出帳日期==="1970/1/1"){response.data[key].出帳日期=""}})
  set_unauthorized_list(response.data)})
}
//#endregion

//#region Table Search 模組
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div style={{padding: 8,}} onKeyDown={(e) => e.stopPropagation()} >
            <Input ref={searchInput} placeholder={`Search ${dataIndex}`} value={selectedKeys[0]} style={{ marginBottom: 8, display: 'block', }}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])} onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            />
            <Space>
            <Button type="primary" onClick={() => handleSearch(selectedKeys, confirm, dataIndex)} icon={<SearchOutlined />} size="small" style={{ width: 90, }} >搜尋</Button>
            <Button onClick={() => {clearFilters && handleReset(clearFilters);confirm({ closeDropdown: false, }); setSearchText(selectedKeys[0]); setSearchedColumn(dataIndex); }} size="small" style={{ width: 90, }} >重置</Button>
            </Space>
            </div>
        ),

        filterIcon: (filtered) => (
        <SearchOutlined  style={{ color: filtered ? '#1890ff' : undefined, }} />
        ),

        onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => { if (visible) { setTimeout(() => searchInput.current?.select(), 100); } },

        render: (text) => ( text ),
  });
// #endregion  
//#region Table ROW SELECTION 模組 

const 重設顯示參數 = async (selectedRowsdata)=>{
  set_日報日期(selectedRowsdata[0].日期)
  set_日報門市(selectedRowsdata[0].門市)
  set_日報承辦(selectedRowsdata[0].承辦)
  set_日報備註(selectedRowsdata[0].備註)
  set_日報帳差(selectedRowsdata[0].帳差)

  set_應有前期(selectedRowsdata[0].前期)
    set_前期台幣現值(selectedRowsdata[0].前期台幣現值)
     set_前期等價現值(selectedRowsdata[0].前期等價現值)
    set_前期刮樂現值(selectedRowsdata[0].前期刮樂現值)

    set_額度增加(selectedRowsdata[0].額度增加)
    set_額度減少(selectedRowsdata[0].額度減少)
    set_應有後期(selectedRowsdata[0].應有後期)
    set_盤點後期(selectedRowsdata[0].盤點後期)

    set_台彩銷售(selectedRowsdata[0].台彩銷售)
    set_台彩兌獎(selectedRowsdata[0].台彩兌獎)
     set_台彩兌獎_電腦型(selectedRowsdata[0].台彩兌獎_電腦型)
     set_台彩兌獎_立即型(selectedRowsdata[0].台彩兌獎_立即型)
    set_運彩銷售(selectedRowsdata[0].運彩銷售)
    set_運彩兌獎(selectedRowsdata[0].運彩兌獎)
      set_運彩兌獎_有效(selectedRowsdata[0].運彩兌獎_有效)
     set_運彩兌獎_無效(selectedRowsdata[0].運彩兌獎_無效)
     set_金流增加(selectedRowsdata[0].金流增加)
     set_金流減少(selectedRowsdata[0].金流減少)
     set_物流增加(selectedRowsdata[0].物流增加)
     set_物流減少(selectedRowsdata[0].物流減少)


     set_台幣現值(selectedRowsdata[0].台幣現值)
    set_等價現值(selectedRowsdata[0].等價現值)
    set_刮樂現值(selectedRowsdata[0].刮樂現值)

    set_現金0001(selectedRowsdata[0].現金0001)
    set_現金0005(selectedRowsdata[0].現金0005)
    set_現金0010(selectedRowsdata[0].現金0010)
     set_現金0050(selectedRowsdata[0].現金0050)
    set_現金0100(selectedRowsdata[0].現金0100)
     set_現金0200(selectedRowsdata[0].現金0200)
     set_現金0500(selectedRowsdata[0].現金0500)
     set_現金1000(selectedRowsdata[0].現金1000)
     set_現金2000(selectedRowsdata[0].現金2000)
}

const rowSelection = {
  onChange: async (selectedRowKeys, selectedRowsdata) => {

    await read_dayinventory(selectedRowsdata[0].日期)
    await read_unauthorized(selectedRowsdata[0].日期)
    await 重設顯示參數(selectedRowsdata)

    }
  ,
};
// #endregion 
//#region Table 模組
const table_columns_未授權 = [
  {
    Key:'0',width: 50,
    title:'#',
    dataIndex:'unauthorized_ID'
  },
  {
    Key:'1',width: 200,
    title:'種類',
    dataIndex:'種類'
  },
  {
    Key:'2',width: 100,
    title:'面額0796',
    dataIndex:'面額0796'
  },
  {
    Key:'3',width: 100,
    title:'面額08',
    dataIndex:'面額08'
  },
  {
    Key:'4',
    title:'銷售日期',width: 100,
    dataIndex:'銷售日期'
  },
  {
    Key:'5',
    title:'入帳日期',width: 100,
    dataIndex:'入帳日期'
  },
  {
    Key:'6',
    title:'備註',width: 150,
    dataIndex:'備註',
    render: (text, record) => {return < span style={{ color: '#0F3B53' , fontSize: '10px' }}>{text}</span>}
  },
  {
    Key:'7',
    title:'出帳日期',width: 200,
    dataIndex:'出帳日期'
  }, 

]
const table_columns_立即型 = [
  {
    Key:'0',width: 100,
    title:'日期',
    dataIndex:'日期',
  },  
  {
    Key:'1',width: 250,
    title:'種類',
    dataIndex:'種類'
  },
  {
    Key:'2',
    title:'後期',width: 100,
    dataIndex:'數量',
  },
  {
    Key:'3',
    title:'備註',width: 200,
    dataIndex:'備註',
  }, 

]
const columns_summary = [
  {
      Key:'0',
      title:'日期',
      dataIndex:'日期',
    },
    {
      Key:'1',
      title:'帳差',
      dataIndex:'帳差',
    },
    {
      Key:'2',
      title:'前期',
      dataIndex:'前期',
    },
    {
      Key:'3',
      title:'▲額度增加',
      dataIndex:'額度增加',
    },
    {
      Key:'4',
      title:'▼額度減少',
      dataIndex:'額度減少',
    },
    {
      Key:'5',
      title:'應有後期',
      dataIndex:'應有後期',
    },
    {
      Key:'6',
      title:'■盤點後期',
      dataIndex:'盤點後期',
    },
];
const Tab_立即型與未授權 = [
  {key: '1',label: (<span><CodeSandboxOutlined />立即型</span>),
    children: 

    <Space direction="vertical">
      <Table size="small" dataSource={dayinventory_today_list} columns={table_columns_立即型} pagination={{ pageSize: 100 }} rowKey={(record) => record.INVENTORY_ID} />
    </Space>
    ,
    
  }
  ,
  {key: '2',label: (<span><WarningOutlined />未授權</span>),
  children: 

  <Space direction="vertical">

    <Table  dataSource={unauthorized_list} columns={table_columns_未授權}  /> 

  </Space>

  ,
    
  },
]

//#endregion
//#region PRELOAD 模組
useEffect(() => {
  const loadData = async () => {
    await read_member()
    await read_item()
  }

  loadData();
}, []);
// #endregion


    
return (
<div> 
<Paper elevation={3}  style={{ width: 1200,marginLeft: "20px" ,  borderRadius: "5px" }} >
      <Form name="查詢報表"  initialValues={{remember: true}} onFinish={API_門市系統_查詢報表}>
        <Space direction='horizontal'>
          <p>起始日</p>
        <Form.Item name="起始日" rules={[{ required: true, message: '必填欄位' }]}  style={{ marginLeft: 0 }}>
          <DatePicker  format="YYYY-MM-DD" placeholder="起始日" style={{ width: 'calc(190px)' }} />
        </Form.Item>
          <p>結束日</p>
        <Form.Item name="結束日" rules={[{ required: true, message: '必填欄位' }]}  style={{ marginLeft: 0 }}>
          <DatePicker  format="YYYY-MM-DD" placeholder="結束日" style={{ width: 'calc(190px)' }} />
        </Form.Item>
          
        <Form.Item>
          <Button type="primary"  htmlType="submit" icon={<SearchOutlined />}  loading={loadings[1]} onClick={() => enterLoading(1,5000)} >查詢</Button>
        </Form.Item>
        </Space>
      </Form>
          <Table   size="small" pagination={{ pageSize: 100 }} rowSelection={{ type: "radio", ...rowSelection, }}  columns={columns_summary}  dataSource={summary_list} rowKey={(record) => record.DAYREPORT_ID}/>
</Paper>
<Paper elevation={3}  style={{ width: 1200,marginLeft: "20px" ,  borderRadius: "5px" }} >
          <Divider orientation="left">🈺現金流量表</Divider>
      
            <Space direction="horizontal" >
              <Paper elevation={3}  style={{ width: 185 }} >
              
                  <Space direction="vertical" style={{ margin: 15 }}>
                    <p style={{ color: '#EB6472' , fontSize: '20px' ,fontWeight: 'bold',textAlign: 'center'}}>
                      應有前期
                    <InputNumber disabled value={應有前期} style={{ width: 85 }} />
                    <Divider /></p>
                    <span>台幣現值：<InputNumber disabled value={前期台幣現值} style={{ width: 85 }} /></span>
                    <span>等價現值：<InputNumber disabled value={前期等價現值} style={{ width: 85 }} /></span>
                    <span>刮樂現值：<InputNumber disabled value={前期刮樂現值} style={{ width: 85 }} /></span>
                    <p style={{ fontSize: '12px' ,fontWeight: 'bold',textAlign: 'center'}}>　</p>
                  </Space>
              
              </Paper>
              <p>➕</p>
              <Paper elevation={3}  style={{ width: 185 }} >
              
                  <Space direction="vertical" style={{ margin: 15 }}>
                    <p style={{ color: '#EB6472' , fontSize: '20px' ,fontWeight: 'bold',textAlign: 'center'}}>
                    額度增加
                    <InputNumber disabled value={額度增加} style={{ width: 85 }} />
                    <Divider /></p>
                    <span>台彩銷售：<InputNumber disabled value={台彩銷售} style={{ width: 85 }} /></span>
                    <span>運彩銷售：<InputNumber disabled value={運彩銷售} style={{ width: 85 }} /></span>
                    <span>金流代收：<InputNumber disabled value={金流增加} style={{ width: 85 }} /></span>
                    <span>刮樂批入：<InputNumber disabled value={物流增加} style={{ width: 85 }} /></span>
                  </Space>
              
              </Paper>
              <p>➖</p>
              <Paper elevation={3}  style={{ width: 185 }} >
              
                  <Space direction="vertical" style={{ margin: 15 }}>
                    <p style={{ color: '#EB6472' , fontSize: '20px' ,fontWeight: 'bold',textAlign: 'center'}}>
                    額度減少
                    <InputNumber disabled value={額度減少} style={{ width: 85 }} />
                    <Divider /></p>
                    <span>台彩兌獎：<InputNumber disabled value={台彩兌獎} style={{ width: 85 }} /></span>
                    <span>運彩兌獎：<InputNumber disabled value={運彩兌獎} style={{ width: 85 }} /></span>
                    <span>金流代付：<InputNumber disabled value={金流減少} style={{ width: 85 }} /></span>
                    <span>刮樂批出：<InputNumber disabled value={物流減少} style={{ width: 85 }} /></span>
                  </Space>
              
              </Paper>
              <p>＝</p>
              <Paper elevation={3}  style={{ width: 185 }} >
              
                  <Space direction="vertical" style={{ margin: 15 }}>
                    <p style={{ color: '#EB6472' , fontSize: '20px' ,fontWeight: 'bold',textAlign: 'center'}}>
                    應有後期(a)
                    <InputNumber disabled value={應有後期} style={{ width: 85 }} />
                    <Divider /></p>
                    <p style={{ color: '#EB6472' , fontSize: '20px' ,fontWeight: 'bold',textAlign: 'center'}}>
                    本日帳差(b-a)
                    <InputNumber disabled value={日報帳差} style={{ width: 100 }} /></p>
                    <p style={{ fontSize: '20px' ,fontWeight: 'bold',textAlign: 'center'}}>　</p>
                  </Space>
              
              </Paper>
              <p>　</p>
              
              <Paper elevation={3}  style={{ width: 185 }} >
                <Space direction="vertical" style={{ margin: 15 }}>
                  <p style={{ color: '#EB6472' , fontSize: '20px' ,fontWeight: 'bold',textAlign: 'center'}}>
                  盤點後期(b)
                  <InputNumber disabled value={盤點後期} style={{ width: 85 }} />
                  <Divider /></p>
                  <span>台幣現值：<InputNumber disabled value={台幣現值} style={{ width: 85 }} /></span>
                  <span>等價現值：<InputNumber disabled value={等價現值} style={{ width: 85 }} /></span>
                  <span>刮樂現值：<InputNumber disabled value={刮樂現值} style={{ width: 85 }} /></span>
                  <p style={{ fontSize: '12px' ,fontWeight: 'bold',textAlign: 'center'}}>　</p>
                </Space>
              </Paper>
            
            </Space>

          <Divider orientation="left">🈺結帳登記表</Divider>
          <Divider></Divider>
    <Space direction="horizontal" >
            
            <Paper elevation={3}  style={{ width: 200 }} >
        
            <Space direction="vertical" style={{ marginLeft: 15 }}>
                <p style={{ color: '#EB6472' , fontSize: '20px' ,fontWeight: 'bold',textAlign: 'center'}}>結帳單<Divider /></p>
                
                <Row>
                <Col flex={1}>
                <Form.Item name="日期"  rules={[{ required: false, message: '必填欄位' }]}  >
                    <span>日期：<Input style={{width: 125}} disabled value={日報日期}  /></span>
                </Form.Item>
        
                <Form.Item name="門市" rules={[{ required: true, message: '必填欄位' }]}  >
                    <span>門市：<Select disabled value={日報門市}  style={{width: 125}} placeholder="門市"   /></span>
                </Form.Item>
        
                <Form.Item name="承辦" rules={[{ required: true, message: '必填欄位' }]}  >
                    <span>承辦：<Select disabled value={日報承辦}  style={{width: 125}} placeholder="承辦"  /></span>
                </Form.Item>
                <Form.Item name="備註"  value={日報備註} rules={[{ required: true, message: '必填欄位' }]}  >
                    <span>備註：</span><TextArea disabled rows={1}  style={{ width: 125}} />
                </Form.Item>
                </Col>
                </Row>
        
            </Space>
        
        
        
        
            </Paper>
        
            <p>　</p>
        
            <Paper elevation={3}  style={{ width: 825 }} >
            <Space direction="vertical" style={{ marginLeft: 15 }}>
                <p style={{ color: '#EB6472' , fontSize: '20px' ,fontWeight: 'bold',textAlign: 'center'}}>報表與現金清點<Divider /></p>
        
        
                <Row>
                <Col flex={1}>
                <p style={{ color: '#EB6472' , fontSize: '20px' ,fontWeight: 'bold',textAlign: 'center'}}>台彩</p>
                <Form.Item name="台彩銷售" rules={[{ required: true, message: '必填欄位' }]}  >
                    <span>台彩銷售：<InputNumber disabled value={台彩銷售} style={{ width: 100}} /></span>
                </Form.Item>
                <Form.Item name="台彩兌獎_電腦型" rules={[{ required: true, message: '必填欄位' }]}  >
                    <span>兌獎電腦：<InputNumber disabled value={台彩兌獎_電腦型} style={{ width: 100}} /></span>
                </Form.Item>
                <Form.Item name="台彩兌獎_立即型" rules={[{ required: true, message: '必填欄位' }]}  >
                    <span>兌獎立即：<InputNumber disabled value={台彩兌獎_立即型} style={{ width: 100}} /></span>
                </Form.Item>
                </Col>
        
                <Col flex={1} style={{ marginLeft: 15 }} >
                <p style={{ color: '#EB6472' , fontSize: '20px' ,fontWeight: 'bold',textAlign: 'center'}}>運彩</p>
                <Form.Item name="運彩銷售" rules={[{ required: true, message: '必填欄位' }]}  >
                    <span>運彩銷售：<InputNumber disabled value={運彩銷售} style={{ width: 100}} /></span>
                </Form.Item>
                <Form.Item name="運彩兌獎_有效" rules={[{ required: true, message: '必填欄位' }]}  >
                    <span>兌獎有效：<InputNumber disabled value={運彩兌獎_有效} style={{ width: 100}} /></span>
                </Form.Item>
                <Form.Item name="運彩兌獎_無效" rules={[{ required: true, message: '必填欄位' }]}  >
                    <span>兌獎無效：<InputNumber disabled value={運彩兌獎_無效} style={{ width: 100}} /></span>
                </Form.Item>
        
                </Col>
                <Col flex={1} style={{ marginLeft: 15 }} >
                <p style={{ color: '#EB6472' , fontSize: '20px' ,fontWeight: 'bold',textAlign: 'center'}}>現金</p>
                <Form.Item name="現金0001" rules={[{ required: true, message: '必填欄位' }]}  >
                    <span>$01：<InputNumber disabled value={現金0001} style={{ width: 75}} /></span>
                </Form.Item>
                <Form.Item name="現金0005" rules={[{ required: true, message: '必填欄位' }]}  >
                    <span>$05：<InputNumber disabled value={現金0005} style={{ width: 75}} /></span>
                </Form.Item>
                <Form.Item name="現金0010" rules={[{ required: true, message: '必填欄位' }]}  >
                    <span>$10：<InputNumber disabled value={現金0010} style={{ width: 75}} /></span>
                </Form.Item>
                </Col>
                <Col flex={1} style={{ marginLeft: 15 }} >
                <p style={{ color: '#EB6472' , fontSize: '20px' ,fontWeight: 'bold',textAlign: 'center'}}>　</p>
                <Form.Item name="現金0050" rules={[{ required: true, message: '必填欄位' }]}  >
                    <span>$050：<InputNumber disabled value={現金0050} style={{ width: 75}} /></span>
                </Form.Item>
                <Form.Item name="現金0100" rules={[{ required: true, message: '必填欄位' }]}  >
                    <span>$100：<InputNumber disabled value={現金0100} style={{ width: 75}} /></span>
                </Form.Item>
                <Form.Item name="現金0200" rules={[{ required: true, message: '必填欄位' }]}  >
                    <span>$200：<InputNumber disabled value={現金0200} style={{ width: 75}} /></span>
                </Form.Item>
                </Col>
                <Col flex={1} style={{ marginLeft: 15 }} >
                <p style={{ color: '#EB6472' , fontSize: '20px' ,fontWeight: 'bold',textAlign: 'center'}}>　</p>
                <Form.Item name="現金0500" rules={[{ required: true, message: '必填欄位' }]}  >
                    <span>$0500：<InputNumber disabled value={現金0500} style={{ width: 75}} /></span>
                </Form.Item>
                <Form.Item name="現金1000" rules={[{ required: true, message: '必填欄位' }]}  >
                    <span>$1000：<InputNumber disabled value={現金1000} style={{ width: 75}} /></span>
                </Form.Item>
                <Form.Item name="現金2000" rules={[{ required: true, message: '必填欄位' }]}  >
                    <span>$2000：<InputNumber disabled value={現金2000} style={{ width: 75}} /></span>
                </Form.Item>
                </Col>
                </Row>
            </Space>
            </Paper>
        

    </Space>
    
    <Divider></Divider> 
    <Tabs   type="card" style={{ marginLeft: "20px" }} defaultActiveKey="1" items={Tab_立即型與未授權}  />
  
</Paper>
    </div>
    
)}