// #region import Zone
import 'antd/dist/reset.css';
import { SearchOutlined,DollarCircleFilled,DropboxCircleFilled,UserOutlined,DollarOutlined,PlusOutlined } from '@ant-design/icons';
import { Space , Button, Select, Table , Input , Form, DatePicker , Tabs  , InputNumber,Typography,Descriptions,Statistic, Popconfirm  } from "antd";
import { useRef,useState,useEffect } from "react";
import Axios from "axios";
// #endregion


export function 查詢會員(adminvalue){

//#region Pre-Load 模組
const loadData = async () => {
  await read_member()
}
useEffect(() => {loadData()}, [])
//#endregion
//#region Member 模組
const [密碼, set_密碼] = useState("00000000")

const [member_list , set_member_list] = useState([]);
const [member_guest_selection , set_member_guest_selection] = useState([])


const read_member = async() => {
  let response = await Axios.put("https://pos-back-end.pktriot.net/member_read",{門市: adminvalue.adminvalue[1],})
  const newarray = response.data.map((item) => {return {value:item.名稱,label:item.名稱 ,}})
  const guest = newarray.filter((item) =>item.label.startsWith('G') );
  set_member_guest_selection(guest)

  let response2 = await Axios.get("https://pos-back-end.pktriot.net/cashflow_balance_read_all")
  set_member_list(response2.data)

}






const member_login = async() => {
  await Axios.get("https://pos-back-end.pktriot.net/member_read").then( (response) => {
      const newarray = response.data
        .filter((item) => item.名稱.startsWith('ST'))
        .filter((item) => item.密碼 === 密碼)
        .map((item) => { return { value: item.名稱, label: item.名稱 }; });
        set_member_guest_selection(newarray);
    }
  );
}

const newmember_create = (table_object) => {

  
  Axios.post(`https://pos-back-end.pktriot.net/NBACM_0202`, { 
    門市: adminvalue.adminvalue[1],
    名稱: table_object.名稱,
    備註: table_object.備註,
    通訊: table_object.通訊,
  })
  


}
//#endregion
//#region Cashflow 模組
const [cashflow_list, set_cashflow_list] = useState([]);

const read_cashflow = async (form_object) => {
  let response = await Axios.put("https://pos-back-end.pktriot.net/cashflow_read_member",{
    門市: adminvalue.adminvalue[1],
    會員: form_object.會員,
    起始日: form_object.起始日,
    結束日: form_object.結束日,
  })

  Object.keys(response.data)
  .forEach((key) => {const timestamp = response.data[key].TIMESTAMP_ID;response.data[key].TIMESTAMP_ID = new Date(timestamp)
  .toLocaleString('zh-TW', {hour12: false})
  .replace(/\//g, "-")})

  set_cashflow_list(response.data)  
}
//#endregion
//#region Table Search 模組
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div style={{padding: 8,}} onKeyDown={(e) => e.stopPropagation()} >
            <Input ref={searchInput} placeholder={`Search ${dataIndex}`} value={selectedKeys[0]} style={{ marginBottom: 8, display: 'block', }}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])} onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            />
            <Space>
            <Button type="primary" onClick={() => handleSearch(selectedKeys, confirm, dataIndex)} icon={<SearchOutlined />} size="small" style={{ width: 90, }} >搜尋</Button>
            <Button onClick={() => {clearFilters && handleReset(clearFilters);confirm({ closeDropdown: false, }); setSearchText(selectedKeys[0]); setSearchedColumn(dataIndex); }} size="small" style={{ width: 90, }} >重置</Button>
            </Space>
            </div>
        ),

        filterIcon: (filtered) => (
        <SearchOutlined  style={{ color: filtered ? '#1890ff' : undefined, }} />
        ),

        onFilter: (value, record) =>
        (record[dataIndex] ? record[dataIndex].toString() : '')
        .toLowerCase()
        .includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => { if (visible) { setTimeout(() => searchInput.current?.select(), 100); } },

        render: (text) => ( text ),
  });
// #endregion  
//#region Table 模組
const columns_cashflow = [
  {
      Key:'0',width: 180,
      title:'交易時間',
      dataIndex:'TIMESTAMP_ID',
      ...getColumnSearchProps('TIMESTAMP_ID'),
    },
    {
      Key:'4',
      title:'交易類型',width: 210,
      dataIndex:'交易類型',
      ...getColumnSearchProps('交易類型'),
    },
    {
      Key:'5',
      title:'額度減少',width: 160,
      dataIndex:'額度減少',
      ...getColumnSearchProps('額度減少'),
  
      render: (text, record) => {return <strong style={{ color: '#4285f4' , fontSize: '20px' }}>{text}</strong>}
    },
    {
      Key:'6',
      title:'額度增加',width: 160,
      dataIndex:'額度增加',
      ...getColumnSearchProps('額度增加'),
  
      render: (text, record) => {return <strong style={{ color: '#EB6472' , fontSize: '20px' }}>{text}</strong>}
    },
    {
      Key:'7',
      title:'備註',width: 200,
      dataIndex:'備註',
      ...getColumnSearchProps('備註'),

      render: (text, record) => {return < span style={{ color: '#0F3B53' , fontSize: '10px' }}>{text}</span>}
    },
    {
      Key:'8',
      title:'餘額',width: 160,editable: true,
      dataIndex:'餘額',
      render: (text, record) => {return <strong style={{ color: '#FFC14D' , fontSize: '20px' }}>{text}</strong>}
    },
];
const columns_member = [
  {
    Key:'0',width: 50,
    title:'餘額',
    dataIndex:'餘額',
    ...getColumnSearchProps('餘額'),
  },
  {
    Key:'1',width: 180,
    title:'名稱',
    dataIndex:'名稱',
    ...getColumnSearchProps('名稱'),
  },
  {
    Key:'2',
    title:'備註',width: 200,editable: true,
    dataIndex:'備註',
    ...getColumnSearchProps('備註'),
  },    
];
const columns_newmember = [
  {
    Key:'1',
    title:'名稱',
    dataIndex:'名稱',
  },
  {
    Key:'2',
    title:'備註',
    dataIndex:'備註',
  },    
  {
    Key:'3',
    title:'通訊',
    dataIndex:'通訊',
  },
];
//#endregion
//#region 即時會員 模組
const [即時會員, set_即時會員] = useState("即時會員額度"); 
const [即時額度, set_即時額度] = useState("?");   
const cashflow_balance_read = async (e) => {
  let response = await Axios.put("https://pos-back-end.pktriot.net/cashflow_balance_read", {會員:e})
  set_即時額度(response.data.餘額) 
}
//#endregion
// #region BUTTON LOADING  模組
const [loadings, setLoadings] = useState([]);
const enterLoading = (index) => {
  setLoadings((prevLoadings) => {
    const newLoadings = [...prevLoadings];
    newLoadings[index] = true;
    return newLoadings;
  });
  setTimeout(() => {
    setLoadings((prevLoadings) => {
      const newLoadings = [...prevLoadings];
      newLoadings[index] = false;
      return newLoadings;
    });
  }, 2000);
};
// #endregion 
//#region Tab 模組
  const Tab_items = [
      {key: '1',label:  (<span><DollarCircleFilled />金流</span>),
        children:
        <div>
          <Form name="查詢區間_金流"  initialValues={{remember: true}} onFinish={read_cashflow}>
            <Space direction='horizontal'>
            <Statistic title={即時會員} value={即時額度} prefix={<DollarOutlined />}  style={{ width: 180 }} />
              <p>會員</p>
            <Form.Item name="會員" rules={[{ required: true, message: '必填欄位' }]}  style={{ marginLeft: 0 }}>
              <Select showSearch style={{width: 160}} suffixIcon={<UserOutlined/>} placeholder="會員" optionFilterProp="children" filterOption={(input, option) =>(option?.label ?? "").includes(input)}
              filterSort={(optionA, optionB) =>(optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())} options={member_guest_selection} 
              onChange={(e)=>{
                set_即時會員(e)
                cashflow_balance_read(e)
              }} />
            </Form.Item>
              <p>起始日</p>
            <Form.Item name="起始日" rules={[{ required: true, message: '必填欄位' }]}  style={{ marginLeft: 0 }}>
              <DatePicker  format="YYYY-MM-DD" placeholder="起始日" style={{ width: 'calc(190px)' }} />
            </Form.Item>
              <p>結束日</p>
            <Form.Item name="結束日" rules={[{ required: true, message: '必填欄位' }]}  style={{ marginLeft: 0 }}>
              <DatePicker  format="YYYY-MM-DD" placeholder="結束日" style={{ width: 'calc(190px)' }} />
            </Form.Item>
              
            <Form.Item>
              <Button type="primary" shape="circle" htmlType="submit" icon={<SearchOutlined />} />
            </Form.Item>

            </Space>
          </Form>
          <Table  showExpandColumn={false} expandRowByClick={true}  columns={columns_cashflow}  dataSource={cashflow_list} rowKey={(record) => record.CASHFLOW_ID}/>
        </div>
      }
      ,
      {key: '3',label: (<span><DropboxCircleFilled />新增</span>),
      children: 
      <div>
        <Form name="新增會員"   onFinish={newmember_create}>
            <Space direction='horizontal'>
            
            <Form.Item  name="名稱">
              <Input  style={{ width:160 }}  placeholder={"名稱"} />
            </Form.Item>
            <Form.Item name="備註">
              <Input  style={{ width:160 }}  placeholder={"備註"} />
            </Form.Item>
            <Form.Item name="通訊">
              <Input  style={{ width:160 }}  placeholder={"電話(純數字)"} />
            </Form.Item>

            <Form.Item>
              <Button type="primary"  htmlType="submit" icon={<PlusOutlined />}  loading={loadings[1]} onClick={() => enterLoading(1)} >新增</Button>
            </Form.Item>

            </Space>
        </Form>


        </div>
    
      }
    ]
//#endregion
    return (
    <div>
        <Tabs   size="large" type="card" style={{ marginLeft: "20px" ,  borderRadius: "5px"}} defaultActiveKey="1" items={Tab_items}  />
    </div>
)}