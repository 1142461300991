// #region import Zone
import 'antd/dist/reset.css';
import Paper from '@mui/material/Paper';
import { CheckOutlined } from '@ant-design/icons';
import { UserOutlined,RedoOutlined } from '@ant-design/icons';
import { Space , Button, Select, Table , Input , Divider, DatePicker ,  InputNumber,Typography,Form,Popover,Row,Col } from "antd";
import { useState,useEffect } from "react";
import Axios from "axios";

// #endregion


export function 品項系統(){ 
//#region 時間模組
const [起始日 , set_起始日] = useState("");
const [結束日 , set_結束日] = useState("");
//#endregion


//#region 品項模組 讀取 更新 刪除
const [品項列表 , set_品項列表] = useState([]);
const API_讀取_立即型全品項 = async() => {

  let response = await Axios.post("https://pos-back-end.pktriot.net/v8ggRgrS")

  
const sortedData = response.data.sort((a, b) => new Date(b.ITEM_ID) - new Date(a.ITEM_ID));

  set_品項列表(sortedData)

}
const API_新增_立即型單品 = async(formdata) => {

  await Axios.post("https://pos-back-end.pktriot.net/NHXs88as",{
    期數:formdata.期數,
    主題:formdata.主題,
    面額:formdata.面額,
  })

  await API_讀取_立即型全品項()

}



const delete_punch = async(table_object) => {
  await Axios.post("https://pos-back-end.pktriot.net/NBACM_0104",{ ID:table_object.ID, })

  set_出勤紀錄(出勤紀錄.filter(item => item.ID !== table_object.ID ));


}


//#endregion


//#region 欄位模組

  const columns_品項列表 = [
    {
        Key:'0',
        title:'品項',
        dataIndex:'items_name',
      },
      {
        Key:'1',
        title:'額度',
        dataIndex:'denomination', 
      },
      {
        Key:'2',
        title:'啟用狀態',
        dataIndex:'啟用狀態', 
      },
      
      
  ]
//#endregion
//#region 預設讀取
const loadData = async () => {
  await API_讀取_立即型全品項()
}
useEffect(() => {loadData()}, [])



// #endregion

//#region 表單模組
const [form_參數項] = Form.useForm()
//#endregion

//#region 前端渲染

return (<div className="App">
<Space direction="horizontal"  align="start">
  <Form  name="管理系統_新增_薪資明細" form={form_參數項}
        onFinish={ (formdata)=>{ API_新增_立即型單品(formdata);form_參數項.resetFields(['期數', '主題', '面額'])}}>
    <Space direction="horizontal" >

      <Paper elevation={3}   >

        <Space direction="vertical" style={{ margin: 15 }}>
          <p style={{ color: '#EB6472' , fontSize: '20px' ,fontWeight: 'bold',textAlign: 'center'}}>參數項<Divider /></p>
          
          <Row>
          <Col flex={1}>
          <Form.Item name="期數" rules={[{required: true,message: '必填欄位'}]}>
            <Input style={{ width: 180 }} placeholder="期數"  type="text" />
          </Form.Item>
          <Form.Item name="主題" rules={[{required: true,message: '必填欄位'}]}>
            <Input style={{ width: 180 }} placeholder="主題"  type="text" />
          </Form.Item>
          <Form.Item name="面額" rules={[{required: true,message: '必填欄位'}]}>
                <Input style={{width: 90 }} placeholder="面額"  type="number" />
            </Form.Item> 
            <Space direction="horizontal" >

            <Form.Item name="新增" >
              <Button   htmlType="submit" icon={<CheckOutlined /> }>新增</Button>
            </Form.Item>

            </Space>

          </Col>
          </Row>

        </Space>




      </Paper>

    </Space>  
  </Form>

  <Paper elevation={3}  style={{ width: 400 }} >
    <Table size="small" pagination={{ pageSize: 100 }} columns={columns_品項列表}  dataSource={品項列表} rowKey={(record) => record.ITEM_ID}  />
  </Paper>

</Space>

</div>)
// #endregion



}